import React, { useState, useEffect, useCallback, MouseEventHandler, useMemo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Box, MenuLink, Text } from 'components'
import { RouteDefinition } from 'compositions/Routes'
import { useLocation } from 'react-router'

export const NavMenuItem: React.FC<{ route: RouteDefinition, routes?: RouteDefinition[], context?: object }> = ({ route, routes, context }) => {
  const RouteIcon = route.icon
  const location = useLocation()
  const [ locationMatch, setLocationMatch ] = useState(route.path !== '*' && ((route.pathMatch || route.path) === location.pathname || !!location.pathname.match(new RegExp(`^\/${(route.pathMatch || route.path)}(\/.*)?`))))
  const [ expanded, setExpanded ] = useState(locationMatch)
  const onClickExpand: MouseEventHandler<HTMLSpanElement> = useCallback((evt) => {
    setExpanded(!expanded)
    evt.stopPropagation()
  }, [ expanded ])

  const BadgeComponent = route.badgeComponent || React.Fragment
  const badgeComponentProps: { anchorOrigin?: { vertical: 'top' | 'bottom', horizontal: 'left' | 'right' } } = route.badgeComponent ? { anchorOrigin: { vertical: 'top', horizontal: 'right' } } : null

  const showNavItem = useMemo(() => {
    if (!route.navMenu) return false

    if (typeof route.navMenu === 'function') return route.navMenu(context)

    return route.navMenu
  }, [route, context])

  const hasChildren = useMemo(() => {
    return routes?.filter((rt) => {
      if (!rt.navMenu) return false

      if (typeof rt.navMenu === 'function') return rt.navMenu(context)
  
      return rt.navMenu
    })?.length > 0
  }, [routes, context])

  useEffect(() => {
    const pathMatch = route.path !== '*' && ((route.pathMatch || route.path) === location.pathname || !!location.pathname.match(new RegExp(`^\/${(route.pathMatch || route.path)}(\/.*)?`)))
    setLocationMatch(pathMatch)
    setExpanded(pathMatch)
  }, [ route, location ])

  if (showNavItem) {
    return <>
      <MenuItem>
        <MenuLink component={RouterLink} to={route.path.replace('*', '')} color={locationMatch ? "primary" : "text.primary"}>
          <BadgeComponent {...badgeComponentProps}>
            { RouteIcon && <RouteIcon sx={{ marginRight: 1 }} /> }
            { route.name }
          </BadgeComponent>
        </MenuLink>

        { hasChildren && <Text color="text.disabled" onClick={onClickExpand}>
          { expanded && <ExpandLess /> }
          { !expanded && <ExpandMore /> }
        </Text> }
      </MenuItem>

      <Box pl={4}>
        { (expanded) && routes?.map((route) => {
          return <NavMenuItem key={route.path} route={route} />
        })}
      </Box>
    </>
  } else {
    return null
  }
}

export default NavMenuItem
