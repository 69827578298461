import { useAppDispatch, useAppSelector } from 'compositions/Application/store'
import {
  drawerSettingsSelector,
  setNavDrawer,
  toggleNavDrawer,
  setAccountMenu,
  toggleAccountMenu,
  setContentDrawer,
  toggleContentDrawer,
  DrawerSettingsState,
} from 'reducers/drawerSettings'

export interface DrawerSettingsActions {
  setNavDrawer: (open: boolean) => ReturnType<typeof setNavDrawer>
  toggleNavDrawer: () => ReturnType<typeof toggleNavDrawer>
  setAccountMenu: (open: boolean) => ReturnType<typeof setAccountMenu>
  toggleAccountMenu: () => ReturnType<typeof toggleAccountMenu>
  setContentDrawer: (open: boolean, type?: string) => ReturnType<typeof setContentDrawer>
  toggleContentDrawer: (type?: string) => ReturnType<typeof toggleContentDrawer>
}

export const useDrawerSettings = (): [DrawerSettingsState, DrawerSettingsActions] => {
  const drawerSettings = useAppSelector(drawerSettingsSelector)
  const dispatch = useAppDispatch()

  const setNavDrawerCallback = (open: boolean) => dispatch(setNavDrawer(open))
  const toggleNavDrawerCallback = () => dispatch(toggleNavDrawer())
  const setAccountMenuCallback = (open: boolean) => dispatch(setAccountMenu(open))
  const toggleAccountMenuCallback = () => dispatch(toggleAccountMenu())
  const setContentDrawerCallback = (open: boolean, type?: string) => dispatch(setContentDrawer([open, type]))
  const toggleContentDrawerCallback = (type?: string) => dispatch(toggleContentDrawer(type))

  return [
    drawerSettings,
    {
      setNavDrawer: setNavDrawerCallback,
      toggleNavDrawer: toggleNavDrawerCallback,
      setAccountMenu: setAccountMenuCallback,
      toggleAccountMenu: toggleAccountMenuCallback,
      setContentDrawer: setContentDrawerCallback,
      toggleContentDrawer: toggleContentDrawerCallback,
    }
  ]
}

export default useDrawerSettings
