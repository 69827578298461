import { enqueueSnackbar } from 'notistack'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { UpdateMeMutationData, useUpdateMe } from 'hooks/useUpdateMe'
import { useMutationForm, MutationFormTuple, OnSuccessFn } from 'hooks/useMutationForm'
import { UpdateMeInput, User} from 'graphql/schema/graphql'

export const zodSchema = z.object({
  firstName: z.string({
    required_error: 'First name is required',
  }).nonempty(),
  middleName: z.union([z.string(), z.null(), z.undefined()]),
  lastName: z.string({
    required_error: 'Last name is required',
  }).nonempty(),
  preferredName: z.union([z.string(), z.null(), z.undefined()]),
})

export const validationSchema = toFormikValidationSchema(zodSchema)

export const useUpdateMeForm = (me: User): MutationFormTuple<UpdateMeMutationData, UpdateMeInput> => {
  const onSuccess: OnSuccessFn<UpdateMeMutationData, UpdateMeInput> = () => {
    enqueueSnackbar("Profile updated", { variant: "success", autoHideDuration: 2000, preventDuplicate: true })
  }

  const [ mutationForm, mutationResult] = useMutationForm(useUpdateMe, {
    onSuccess,
    initialValues: {
      firstName: me?.firstName || '',
      middleName: me?.middleName || '',
      lastName: me?.lastName || '',
      preferredName: me?.preferredName || '',
    }
  })

  return [ { ...mutationForm, validationSchema }, mutationResult ]
}

export default useUpdateMeForm