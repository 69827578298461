import { Account } from './Account'
import { Add } from './Add'
import { Admin } from './Admin'
import { ArrowBack } from './ArrowBack'
import { Assignment } from './Assignment'
import { Back } from './Back'
import { Calendar } from './Calendar'
import { Cancel } from './Cancel'
import { Cart } from './Cart'
import { Catalog } from './Catalog'
import { Check } from './Check'
import { Choices } from './Choices'
import { Close } from './Close'
import { Delete } from './Delete'
import { Download } from './Download'
import { Eye } from './Eye'
import { Error } from './Error'
import { Filters } from './Filters'
import { Gallery } from './Gallery'
import { Home } from './Home'
import { Help } from './Help'
import { Ignore } from './Ignore'
import { Incomplete } from './Incomplete'
import { Link } from './Link'
import { Lock } from './Lock'
import { Login } from './Login'
import { Logout } from './Logout'
import { Menu } from './Menu'
import { Next } from './Next'
import { Orders } from './Orders'
import { Pencil } from './Pencil'
import { Rosters } from './Rosters'
import { Rows } from './Rows'
import { Save } from './Save'
import { School } from './School'
import { Students } from './Students'
import { Table } from './Table'
import { Vendor } from './Vendor'
import { Meals } from './Meals'
import { Servings } from './Servings'
import { Pizza } from './Pizza'
import { Settings } from './Settings'
import { Documents } from './Documents'
import { MenuPreferences } from './MenuPreferences'
import { ManageAccount } from './ManageAccount'
import { Payment } from './Payment'
import { Campus } from './Campus'
import { Kitchen } from './Kitchen'
import { Kiosk } from './Kiosk'
import { Market } from './Market'
import { Subtract } from './Subtract'
import { Like } from './Like'
import { Liked } from './Liked'
import { Dislike } from './Dislike'
import { Disliked } from './Disliked'

export {
  Account,
  Add,
  Admin,
  ArrowBack,
  Assignment,
  Back,
  Calendar,
  Cancel,
  Cart,
  Catalog,
  Check,
  Choices,
  Close,
  Delete,
  Download,
  Eye,
  Error,
  Filters,
  Gallery,
  Home,
  Help,
  Ignore,
  Incomplete,
  Link,
  Lock,
  Login,
  Logout,
  Menu,
  Next,
  Orders,
  Pencil,
  Rosters,
  Rows,
  Save,
  School,
  Students,
  Table,
  Vendor,
  Meals,
  Servings,
  Pizza,
  Settings,
  Documents,
  MenuPreferences,
  ManageAccount,
  Payment,
  Campus,
  Kitchen,
  Kiosk,
  Market,
  Subtract,
  Like,
  Liked,
  Dislike,
  Disliked,
}

export default {
  Account,
  Add,
  Admin,
  ArrowBack,
  Assignment,
  Back,
  Calendar,
  Cancel,
  Cart,
  Catalog,
  Check,
  Choices,
  Close,
  Delete,
  Download,
  Eye,
  Error,
  Filters,
  Gallery,
  Home,
  Help,
  Ignore,
  Incomplete,
  Link,
  Lock,
  Login,
  Logout,
  Menu,
  Next,
  Orders,
  Pencil,
  Rows,
  Rosters,
  Save,
  School,
  Students,
  Table,
  Vendor,
  Meals,
  Servings,
  Pizza,
  Settings,
  Documents,
  MenuPreferences,
  ManageAccount,
  Payment,
  Campus,
  Kitchen,
  Kiosk,
  Market,
  Subtract,
  Like,
  Liked,
  Dislike,
  Disliked,
}
