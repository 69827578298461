import React from 'react'
import { MobileStepper as MuiStepper, styled } from '@mui/material'
import { Button, ExtendedButtonProps } from 'components/Button'
import { TodosWizard } from 'hooks/useTodosWizard'
import { Back, Next } from 'icons'

const TodoStepper = styled(MuiStepper)(({ theme, position="top" }) => ({
  ...(position === "top" && {
    top: theme.spacing(8),
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: '#ccc',
  }),
}))

export type MobileStepperProps = { wizard: TodosWizard, position?: "top" | "bottom" | "static" }

type StepButtonProps = ExtendedButtonProps & Pick<MobileStepperProps, 'wizard'>

const NextButton: React.FC<StepButtonProps> = ({ wizard }) => {
  const navigateToNext = () => {
    const incomplete = wizard.todos.filter((todo) => todo.id !== wizard.activeTodo.id && todo.status === 'incomplete').length

    if ((incomplete > 0 || !wizard.activeTodo.resolved) && wizard.todos.length > 1) {
      wizard.navigateToNext({ onlyIncomplete: false })
    } else {
      wizard.navigateToNext({ onlyIncomplete: true, cycle: false })
    }
  }

  return <Button variant="text" color="plain" fullWidth={false} onClick={() => {
    if (wizard.activeTodo.resolved && wizard.activeTodo.status !== 'completed') {
      wizard.resolveActiveTodo().then(() => navigateToNext())
    } else if (!wizard.activeTodo.definition.required && wizard.activeTodo.status !== 'ignored') {
      wizard.ignoreActiveTodo().then(() => navigateToNext())
    } else {
      navigateToNext()
    }
  }}>
    <Next fontSize="large" />
  </Button>
}

const BackButton: React.FC<StepButtonProps> = ({ wizard }) => {
  return <Button variant="text" color="plain" fullWidth={false} onClick={() => wizard.navigateToPrev({ onlyIncomplete: false, cycle: false })}><Back fontSize="large" /></Button>
}

export const MobileStepper: React.FC<MobileStepperProps> = ({ wizard, position="top" }) => {
  return <TodoStepper
      variant="progress"
      position={position}
      steps={wizard.todos.length}
      activeStep={wizard.activeStep}
      nextButton={<NextButton wizard={wizard} />}
      backButton={<BackButton wizard={wizard} />}
    />
}

export default MobileStepper
