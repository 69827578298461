import React from 'react'
import MuiModal, { ModalProps } from '@mui/material/Modal'
import { styled } from '@mui/material/styles'
import { Box } from 'components'

export const ModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: 'white',
  borderRadius: 1,
  outline: 'none',
  backgroundColor: 'white',
  color: 'inherit',
  padding: theme.spacing(4),
}))

export const Modal = <C extends React.ElementType='div'>({ children, open, ...props }: ModalProps<C, { component?: C }>) => <MuiModal open={open} {...props}>
  <ModalBox>
    {children}
  </ModalBox>
</MuiModal>

export { MuiModal }
export default Modal
