import { useEffect, useCallback, useMemo } from 'react'
import { setUser } from '@sentry/react'
import { meQuery } from 'graphql/queries'
import { useLazyQuery, useToken } from 'hooks'
import type { Organization, Location, User, SchoolLocation, VendorLocation, School, Vendor } from 'graphql/schema/graphql'
import { useAppDispatch, useAppSelector } from 'compositions/Application/store'
import { meSelector, setMe } from 'reducers/me'
import type { AppName } from './useBaseUrl'


// TODO: We should pull these from the backend
// NOTE: These are the same across locations and organizations
export const RoleLevels = {
  ADMIN: 100,
  STAFF: 50,
  KIOSK: 40,
}
export const AdminRoleLevels = {
  ADMIN: 100,
  SUPPORT: 80,
  SCHOOLS: 60,
  VENDORS: 60,
  SALES: 50,
}

export const useMeAuthMethods = (me?: User) => {
  const hasAdminRole = useCallback((role: string):boolean => {
    const foundRole = me?.userRoles?.find((userRole) => {
      return userRole.__typename === "AdminRole" && (userRole.role === role || userRole.level >= AdminRoleLevels[userRole.role])
    })

    return !!foundRole
  }, [me])

  const hasAdminLevel = useCallback((level: number):boolean => {
    const foundRole = me?.userRoles?.find((userRole) => {
      return userRole.__typename === "AdminRole" && userRole.level >= level
    })

    return !!foundRole
  }, [me])

  const hasOrganizationRole = useCallback((role: string, organization: Organization | School | Vendor | Location):boolean => {
    const foundRole = me?.userRoles?.find((userRole) => {
      return userRole.__typename === "OrganizationRole" && userRole.organizationId === organization.id &&
        (userRole.role === role || userRole.level >= RoleLevels[role])
    })

    return !!foundRole
  }, [me])

  const hasAnyOrganizationRole = useCallback((role: string, organizationType: 'SCHOOL' | 'VENDOR'):boolean => {
    const foundRole = me?.userRoles?.find((userRole) => {
      return userRole.__typename === "OrganizationRole" && userRole.organizationType === organizationType &&
        (userRole.role === role || userRole.level >= RoleLevels[role])
    })

    return !!foundRole
  }, [me])

  const hasOrganizationLevel = useCallback((level: number, organization: Organization | School | Vendor | Location):boolean => {
    const foundRole = me?.userRoles?.find((userRole) => {
      return userRole.__typename === "OrganizationRole" && userRole.organizationId === organization.id && userRole.level >= level
    })

    return !!foundRole
  }, [me])

  const hasAnyOrganizationLevel = useCallback((level: number, organizationType: 'SCHOOL' | 'VENDOR'):boolean => {
    const foundRole = me?.userRoles?.find((userRole) => {
      return userRole.__typename === "OrganizationRole" && userRole.organizationType === organizationType && userRole.level >= level
    })

    return !!foundRole
  }, [me])

  const hasLocationRole = useCallback((role: string, location: Location | SchoolLocation | VendorLocation):boolean => {
    const foundRole = me?.userRoles?.find((userRole) => {
      return ((userRole.__typename === "LocationRole" && userRole.locationId === location.id) || (userRole.__typename == "OrganizationRole" && userRole.organizationId === (location.organization?.id || location.organizationId))) &&
        (userRole.role === role || userRole.level >= RoleLevels[role])
    })

    return !!foundRole
  }, [me])

  const hasAnyLocationRole = useCallback((role: string, locationType: 'SCHOOL_LOCATION' | 'VENDOR_LOCATION'):boolean => {
    const foundRole = me?.userRoles?.find((userRole) => {
      return (userRole.__typename === "LocationRole" && userRole.locationType === locationType) &&
        (userRole.role === role || userRole.level >= RoleLevels[role])
    })

    return !!foundRole
  }, [me])

  const hasLocationLevel = useCallback((level: number, location: Location | SchoolLocation | VendorLocation):boolean => {
    const foundRole = me?.userRoles?.find((userRole) => {
      return ((userRole.__typename === "LocationRole" && userRole.locationId === location.id) || (userRole.__typename == "OrganizationRole" && userRole.organizationId === (location.organization?.id || location.organizationId))) &&
        userRole.level >= level
    })

    return !!foundRole
  }, [me])

  const hasAnyLocationLevel = useCallback((level: number, locationType: 'SCHOOL_LOCATION' | 'VENDOR_LOCATION'):boolean => {
    const foundRole = me?.userRoles?.find((userRole) => {
      return (userRole.__typename === "LocationRole" && userRole.locationType === locationType) &&
        userRole.level >= level
    })

    return !!foundRole
  }, [me])

  const hasRole = useCallback((role: string, context?: Organization | School | Vendor | Location | SchoolLocation | VendorLocation):boolean => {
    switch (context?.__typename) {
      case undefined:
        return hasAdminRole(role)
      case 'Organization':
        return hasOrganizationRole(role, context)
      case 'School':
        return hasOrganizationRole(role, context)
      case 'Vendor':
        return hasOrganizationRole(role, context)
      case 'Location':
        return hasLocationRole(role, context)
      case 'SchoolLocation':
        return hasLocationRole(role, context)
      case 'VendorLocation':
        return hasLocationRole(role, context)
    }
  }, [hasAdminRole, hasOrganizationRole, hasLocationRole])

  const hasAnyRole = useCallback((role: string, contextType?: 'SCHOOL' | 'VENDOR' | 'SCHOOL_LOCATION' | 'VENDOR_LOCATION'):boolean => {
    switch (contextType) {
      case undefined:
        return hasAdminRole(role)
      case 'SCHOOL':
        return hasAnyOrganizationRole(role, contextType) || hasAnyLocationRole(role, 'SCHOOL_LOCATION')
      case 'VENDOR':
        return hasAnyOrganizationRole(role, contextType) || hasAnyLocationRole(role, 'VENDOR_LOCATION')
      case 'SCHOOL_LOCATION':
        return hasAnyLocationRole(role, contextType) || hasAnyOrganizationRole(role, 'SCHOOL')
      case 'VENDOR_LOCATION':
        return hasAnyLocationRole(role, contextType) || hasAnyOrganizationRole(role, 'VENDOR')
    }
  }, [hasAdminRole, hasAnyOrganizationRole, hasAnyLocationRole])

  const hasLevel = useCallback((level: number, context?: Organization | School | Vendor | Location | SchoolLocation | VendorLocation):boolean => {
    switch (context?.__typename) {
      case undefined:
        return hasAdminLevel(level)
      case 'Organization':
        return hasOrganizationLevel(level, context)
      case 'School':
        return hasOrganizationLevel(level, context)
      case 'Vendor':
        return hasOrganizationLevel(level, context)
      case 'Location':
        return hasLocationLevel(level, context)
      case 'SchoolLocation':
        return hasLocationLevel(level, context)
      case 'VendorLocation':
        return hasLocationLevel(level, context)
    }
  }, [hasAdminLevel, hasOrganizationLevel, hasLocationLevel])

  const hasAnyLevel = useCallback((level: number, contextType?: 'SCHOOL' | 'VENDOR' | 'SCHOOL_LOCATION' | 'VENDOR_LOCATION'):boolean => {
    switch (contextType) {
      case undefined:
        return hasAdminLevel(level)
      case 'SCHOOL':
        return hasAnyOrganizationLevel(level, contextType) || hasAnyLocationLevel(level, 'SCHOOL_LOCATION')
      case 'VENDOR':
        return hasAnyOrganizationLevel(level, contextType) || hasAnyLocationLevel(level, 'VENDOR_LOCATION')
      case 'SCHOOL_LOCATION':
        return hasAnyLocationLevel(level, contextType) || hasAnyOrganizationLevel(level, 'SCHOOL')
      case 'VENDOR_LOCATION':
        return hasAnyLocationLevel(level, contextType) || hasAnyOrganizationLevel(level, 'VENDOR')
    }
  }, [hasAdminLevel, hasAnyOrganizationLevel, hasAnyLocationLevel])

  // TODO: REPLACE ADMIN CHECK FOR MARKET APP WITH FEATURE FLAG
  const hasMarketAccess = useMemo(() => !!me?.features?.find((feature) => feature.key === 'market_app')?.enabled && !!me?.account, [me])
  const hasCampusAccess = useMemo(() => me?.schoolLocations?.length > 0 && hasAnyRole('STAFF', 'SCHOOL_LOCATION'), [me, hasAnyRole])
  const hasKioskAccess = useMemo(() => me?.schoolLocations?.length > 0 && hasAnyRole('KIOSK', 'SCHOOL_LOCATION'), [me, hasAnyRole])
  const hasKitchenAccess = useMemo(() => me?.vendorLocations?.length > 0 && hasAnyRole('STAFF', 'VENDOR_LOCATION'), [me, hasAnyRole])
  const hasLuxoAccess = useMemo(() => hasAdminLevel(1), [hasAdminLevel])
  const hasOtherAccess = useCallback((app?: AppName) => {
    switch (app) {
      case 'market':
        return hasCampusAccess || hasKioskAccess || hasKitchenAccess || hasLuxoAccess
      case 'campus':
        return hasMarketAccess || hasKioskAccess || hasKitchenAccess || hasLuxoAccess
      case 'kiosk':
        return hasMarketAccess || hasCampusAccess || hasKitchenAccess || hasLuxoAccess
      case 'kitchen':
        return hasMarketAccess || hasCampusAccess || hasKioskAccess || hasLuxoAccess
      case 'luxo':
        return hasMarketAccess || hasCampusAccess || hasKioskAccess || hasKitchenAccess
      default:
        return hasMarketAccess || hasCampusAccess || hasKioskAccess || hasKitchenAccess || hasLuxoAccess
    }
  }, [hasMarketAccess, hasCampusAccess, hasKioskAccess, hasKitchenAccess, hasLuxoAccess])

  return {
    hasAdminRole,
    hasAdminLevel,
    hasOrganizationRole,
    hasOrganizationLevel,
    hasLocationRole,
    hasLocationLevel,
    hasRole,
    hasLevel,
    hasAnyOrganizationRole,
    hasAnyOrganizationLevel,
    hasAnyLocationRole,
    hasAnyLocationLevel,
    hasAnyRole,
    hasAnyLevel,
    hasMarketAccess,
    hasCampusAccess,
    hasKioskAccess,
    hasKitchenAccess,
    hasLuxoAccess,
    hasOtherAccess,
  }
}

export const useMeReducer = () => {
  const { token, parsed } = useToken()
  const { me } = useAppSelector(meSelector)
  const dispatch = useAppDispatch()
  const authMethods = useMeAuthMethods(me)

  const setMeCallback = useCallback((me: User) => dispatch(setMe(me)), [ dispatch ])

  const featureEnabled = useCallback((feature: string): boolean => {
    if (me?.features?.find((feat) => feat.key === feature && feat.enabled )) {
      return true
    } else {
      return false
    }
  }, [me])

  return {
    token,
    parsed,
    me,
    setMe: setMeCallback,
    ...authMethods,
    featureEnabled,
  }
}

export const useMeQuery = () => {
  const [getMe, { data, called, ...rest } ] = useLazyQuery<{ me: User }>(meQuery, { pollInterval: 600000 })
  const { token, me, setMe, ...authMethods } = useMeReducer()

  useEffect(() => {
    if (!!token && !called) {
      getMe()
    }
  }, [ getMe, token, called ])

  useEffect(() => {
    if (data?.me?.id) {
      setUser({ id: data.me.id, email: data.me.emailAddress })
      setMe(data.me)
    } else {
      setUser(null)
    }
  }, [ data?.me, setMe ])

  return {
    ...rest,
    token,
    me,
    data: {
      ...data,
      me,
    },
    ...authMethods,
  }
}

export const useMe = () => {
  const me = useMeReducer()

  return me
}

export default useMe
