import { useEffect, useMemo } from "react"
import { enqueueSnackbar } from "notistack"

export const useFlashMessages = () => {
  const metaFlashMessages: HTMLMetaElement = document.head.querySelector('meta[name="flashMessages"]')
  const flashMessages: string | undefined = metaFlashMessages ? metaFlashMessages.content : undefined
  const jsonFlashMessages: (['default' | 'error' | 'success' | 'warning' | 'info', string])[] = useMemo(() => flashMessages ? JSON.parse(flashMessages) : [], [flashMessages])

  useEffect(() => {
    if (!!jsonFlashMessages) {
      jsonFlashMessages.forEach((message) => {
        enqueueSnackbar(message[1], { variant: message[0], autoHideDuration: 3000 })
      })

      metaFlashMessages?.remove()
    }
  }, [jsonFlashMessages, metaFlashMessages])

  return {}
}

export default useFlashMessages