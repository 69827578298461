import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import { MenuLink } from 'components'
import { useMe, useLoginUrl } from 'hooks'
import { Kiosk as KioskIcon } from 'icons'

export type KioskLinkProps = {
  me: ReturnType<typeof useMe>
  onClick?: React.MouseEventHandler
}

export const KioskLink: React.FC<KioskLinkProps> = ({ me, onClick }) => {
  const kioskLoginUrl = useLoginUrl('kiosk')

  if (!me.hasKioskAccess) return null

  return <MenuItem onClick={onClick}>
    <MenuLink href={kioskLoginUrl} sx={(theme) => ({ fontWeight: 'bold', color: theme.palette.kiosk.main })}>
      <KioskIcon sx={{ marginRight: 1 }} />
      Cafeteria Kiosk
    </MenuLink>
  </MenuItem>
}

export default KioskLink
