import React from 'react'
import { Alert, Form, FormTextField, TextField, FormSubmitButton } from 'components'
import { UpdateMeInput, User } from 'graphql/schema/graphql'
import { MutationFormTuple } from 'hooks/useMutationForm'
import { UpdateMeMutationData } from 'hooks/useUpdateMe'

export const UpdateProfileForm: React.FC<{ me: User, form: MutationFormTuple<UpdateMeMutationData, UpdateMeInput> }> = ({ me, form }) => {

  const [ mutationForm, { errors, loading, submitted } ] = form

  return (
    <Form form={mutationForm}>

      <TextField
        id="email"
        name="email"
        label="Email address"
        value={me.emailAddress}
        disabled
      />
      <FormTextField
        id="firstName"
        name="firstName"
        label="First name"
      />
      <FormTextField
        id="middleName"
        name="middleName"
        label="Middle name"
      />
      <FormTextField
        id="lastName"
        name="lastName"
        label="Last name"
      />
      <FormTextField
        id="preferredName"
        name="preferredName"
        label="Preferred name"
      />

      { !submitted && <>{ errors.map((error) => <Alert key={`${error.code}:${error.message}`} severity="error" sx={{ width: '100%', mb: 2 }}>
        {error.message}
      </Alert>)}</> }

      <FormSubmitButton
        loading={loading}
      >
        Update profile
      </FormSubmitButton>
    </Form>
  )
}

export default UpdateProfileForm
