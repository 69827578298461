import React from 'react'
import { Button, ExtendedButtonProps } from 'components/Button'

export const FormSubmitButton: React.FC<ExtendedButtonProps> = ({ children, loading, disabled, color='plain', ...props }) => {
    return (
        <Button
          {...props}
          type="submit"
          loading={loading}
          disabled={typeof disabled === 'boolean' ? disabled : loading}
          color={color}
        >
            {children}
        </Button>
    )
}

export default FormSubmitButton
