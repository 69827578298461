import React from 'react'
import {
  PopoverFooter,
  PopoverFooterProps,
} from 'components/Popover'
import {
  DialogActions,
  DialogActionsProps,
} from 'components/Dialog'
import { useViewport } from 'hooks'

export type FiltersPopoverFooterProps = PopoverFooterProps & DialogActionsProps

export const FiltersPopoverFooter: React.FC<FiltersPopoverFooterProps> = (props) => {
  const { isMedium} = useViewport()

  if (isMedium) {
    return <PopoverFooter {...props as PopoverFooterProps} />
  } else {
    return <DialogActions {...props as DialogActionsProps} />
  }
}

export default FiltersPopoverFooter
