import React from 'react'
import MuiRadio, { RadioProps } from '@mui/material/Radio'

declare module '@mui/material/Radio' {
  interface RadioPropsColorOverrides {
    plain: true
    branding: true
    campus: true
    kitchen: true
    kiosk: true
    luxo: true
    market: true
    tools: true
  }
}

export const Radio = (props: RadioProps) => <MuiRadio {...props} />

export default Radio
