import { useEffect } from 'react'
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom'
import * as Sentry from '@sentry/react'

type SampleRateConfig = {
  [key in 'local' | 'development' | 'staging' | 'demo' | 'production']: number
}

const tracesSampleConfig: SampleRateConfig = {
  local: 0.0,
  development: 0.0,
  staging: 0.0,
  demo: 0.0,
  production: 0.2,
}

const replaysSessionsConfig: SampleRateConfig = {
  local: 0.0,
  development: 0.0,
  staging: 0.0,
  demo: 0.0,
  production: 0.0,
}

const replaysOnErrorConfig: SampleRateConfig = {
  local: 0.0,
  development: 0.0,
  staging: 0.0,
  demo: 0.0,
  production: 0.8,
}

export const init = () => {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT || process.env.NODE_ENV,

    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      })
    ],

    tracesSampleRate: tracesSampleConfig[process.env.SENTRY_ENVIRONMENT || process.env.NODE_ENV],
    replaysSessionSampleRate: replaysSessionsConfig[process.env.SENTRY_ENVIRONMENT || process.env.NODE_ENV],
    replaysOnErrorSampleRate: replaysOnErrorConfig[process.env.SENTRY_ENVIRONMENT || process.env.NODE_ENV],
  })
}

export default init
