import { merge } from 'lodash'
import { useLocation } from 'react-router'
import type { LocationWithFromPath } from 'types/routes'

// TODO: Eventually have this also return a setQueryParam() function that can be used to update the url params and push into router history
export const useQueryParams = <T>(defaults: T): T => {
  const location: LocationWithFromPath = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const parsed: Partial<T> = {}

  Object.keys(defaults).forEach((key) => {
    const val = urlParams.get(key)

    if (!val || val.toLowerCase() === 'null') {
      parsed[key] = undefined
    } else if (val.toLowerCase() === 'true') {
      parsed[key] = true
    } else if (val.toLowerCase() === 'false') {
      parsed[key] = false
    } else if (typeof defaults[key] === 'number') {
      parsed[key] = parseInt(val)
    } else {
      parsed[key] = val
    }
  })

  return merge({}, defaults, parsed)
}

export default useQueryParams
