import React from 'react'
import { Button, ExtendedButtonProps } from 'components/Button'
import { SetFilters } from 'hooks/useFilters'
import { DateTime } from 'luxon'

export type DateRangeButtonFilterProps<T extends object> = React.PropsWithChildren<{
  startDate: DateTime,
  endDate: DateTime,
  newStartDate: DateTime,
  newEndDate: DateTime,
  setFilters: SetFilters<T>
} & ExtendedButtonProps>


export function DateRangeButtonFilter<T extends object>({
  children,
  startDate,
  endDate,
  newStartDate,
  newEndDate,
  setFilters,
  size="small",
  fullWidth=false,
  ...buttonProps
}: DateRangeButtonFilterProps<T>) {
  // const variant: "contained" | "text" = (startDate.format('YYYY-MM-DD') === newStartDate.format('YYYY-MM-DD') && endDate.format('YYYY-MM-DD') === newEndDate.format('YYYY-MM-DD')) ? "contained" : "text"
  const variant: "contained" | "text" = (startDate.toFormat("y-LL-dd") === newStartDate.toFormat("y-LL-dd") && endDate.toFormat("y-LL-dd") === newEndDate.toFormat("y-LL-dd")) ? "contained" : "text"

  return <Button
    variant={variant}
    size={size}
    fullWidth={fullWidth}
    onClick={() => setFilters({ startDate: newStartDate, endDate: newEndDate } as T)}
    {...buttonProps}
  >{children}</Button>
}

export default DateRangeButtonFilter
