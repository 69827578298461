import React from 'react'
import getContrastTextColor from 'utils/getContrastTextColor'
import { Chip } from 'components'
import { Icon, ChipPropsColorOverrides } from '@mui/material'
import { SystemStyleObject } from '@mui/system'
import { OverridableStringUnion } from '@mui/types'
import { Tag } from 'graphql/schema/graphql'

export const TagChip: React.FC<{ tag: Tag, sx?: SystemStyleObject, defaultColor?: OverridableStringUnion<"default" | "primary" | "secondary" | "error" | "info" | "success" | "warning", ChipPropsColorOverrides> }> = ({ tag, sx, defaultColor }) => {
  return <Chip label={tag.name} icon={tag.icon ? <Icon color="inherit">{ tag.icon }</Icon> : undefined} sx={[ { mr: 1, mt: 1, backgroundColor: tag.color, color: getContrastTextColor(tag.color) }, sx ]} color={defaultColor} />
}

export default TagChip
