import React, { Fragment } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Text } from 'components'

export type EmptyStateProps = {
  message: string;
  icon?: React.ElementType;
  iconSize?: string | number;
  iconSx?: object;
  showBorder?: boolean;
  wrapper?: React.ElementType;
}

const Wrapper = styled(Box)(({ theme: { palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  flex: '1 auto',
  padding: '5rem 1rem',
  color: palette.grey[500],
  height: '100%',
}))

const BorderWrapper = styled(Wrapper)(({ theme: { palette } }) => ({
  borderRadius: '10px',
  border: `1px solid ${palette.divider}`,
}))

const EmptyState: React.FC<EmptyStateProps> = ({ icon, iconSize, iconSx, message, showBorder, wrapper }) => {
    const WrapperEl = wrapper || (showBorder ? BorderWrapper : Wrapper)
    const IconEl = icon || Fragment
    return (
      <WrapperEl>
        {icon && <IconEl sx={{ fontSize: iconSize, marginBottom: '1rem', ...iconSx }} />}
        <Text>{message}</Text>
      </WrapperEl>
   )
}

EmptyState.defaultProps = {
  showBorder: false,
  iconSize: '4rem',
  iconSx: null,
}

export { EmptyState }

export default EmptyState
