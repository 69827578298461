import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import { MenuLink } from 'components'
import { useMe, useLoginUrl } from 'hooks'
import { Admin as AdminIcon } from 'icons'

export type LuxoLinkProps = {
  me: ReturnType<typeof useMe>
  onClick?: React.MouseEventHandler
}

export const LuxoLink: React.FC<LuxoLinkProps> = ({ me, onClick }) => {
  const luxoLoginUrl = useLoginUrl('luxo')

  if (!me.hasLuxoAccess) return null

  return <MenuItem onClick={onClick}>
    <MenuLink href={luxoLoginUrl} sx={(theme) => ({ fontWeight: 'bold', color: theme.palette.luxo.main })}>
      <AdminIcon sx={{ marginRight: 1 }} />
      Ordo Admin
    </MenuLink>
  </MenuItem>
}

export default LuxoLink
