import React, { PropsWithChildren, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { SvgIconTypeMap } from '@mui/material'
import { useDrawerSettings } from 'hooks'
import { Filters as FiltersIcon } from 'icons'
import { Box, Button } from 'components'
import { ContentDrawer } from './ContentDrawer'

export interface ContentDrawerPortalProps {
  icon?: OverridableComponent<SvgIconTypeMap>
  overflow?: "hidden" | "auto"
  hideToggle?: boolean
  drawerType?: string
}

export const ContentDrawerPortal: React.FC<PropsWithChildren<ContentDrawerPortalProps>> = ({ children, icon, overflow, hideToggle, drawerType, ...props }) => {
  const DrawerIcon = icon || FiltersIcon
  const [domReady, setDomReady] = useState(false)
  const [{ contentDrawerOpen, contentDrawerType }, { setContentDrawer, toggleContentDrawer }] = useDrawerSettings()

  useEffect(() => {
    setDomReady(true)
  }, [])

  return <>
    { domReady && !hideToggle && createPortal(<Button color="inherit" variant="text" onClick={() => toggleContentDrawer(drawerType)} fullWidth={false}><DrawerIcon fontSize="large" /></Button>, document.getElementById("NavBarPortal")) }
    { domReady && createPortal(
      <Box>
        <ContentDrawer open={contentDrawerOpen && drawerType === contentDrawerType} setDrawer={(open: boolean) => setContentDrawer(open, drawerType)} {...props}>
          <Box p={overflow === "hidden" ? 0 : 2} height="100%" overflow={overflow}>
            { children }
          </Box>
        </ContentDrawer>
      </Box>, document.getElementById("ContentDrawerPortal")
    )}
  </>
}

export default ContentDrawerPortal
