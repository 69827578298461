import React from 'react'
import { useMe } from 'hooks'
import type { AppName } from 'hooks/useBaseUrl'
import {
  MarketLink,
  CampusLink,
  KioskLink,
  KitchenLink,
  LuxoLink,
} from '.'

export const AppLinks: React.FC<{ appName?: AppName, me: ReturnType<typeof useMe>, onClick: React.MouseEventHandler }> = ({ appName, me, onClick }) => {
  return <>
    { appName !== 'kiosk' && <KioskLink me={me} onClick={onClick} /> }
    { appName !== 'kitchen' && <KitchenLink me={me} onClick={onClick} /> }
    { appName !== 'campus' && <CampusLink me={me} onClick={onClick} /> }
    { appName !== 'market' && <MarketLink me={me} onClick={onClick} /> }
    { appName !== 'luxo' && <LuxoLink me={me} onClick={onClick} /> }
  </>
}

export default AppLinks
