import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'compositions/Application/store'
import type { User } from 'graphql/schema/graphql'

export interface MeState {
  me?: User
}

const initialState: MeState = {
  me: undefined,
}

export const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    setMe: (state, action: PayloadAction<User>) => {
      state.me = action.payload
    },
  },
})

export const {
  setMe,
} = meSlice.actions

export const meSelector = (state: RootState) => state.me

export default meSlice.reducer