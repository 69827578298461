import React from 'react'
import { Box, BoxProps } from '../Box'
import styled from '@mui/material/styles/styled'

export type PageProps = BoxProps & { fullWidth?: boolean, autoHeight?: boolean, unpadded?: boolean, noMargins?: boolean }

export const PageBox = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'fullWidth' && prop !== 'autoHeight' && prop !== 'unpadded' && prop !== 'noMargins',
})<PageProps>(({ theme, fullWidth, autoHeight, unpadded, noMargins }) => ({
  ...(!unpadded && {
    [theme.breakpoints.up('xs')]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  }),

  ...(!autoHeight && {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  }),

  ...(!fullWidth && {
    maxWidth: theme.spacing(theme.sizes.pageWidth),
  }),

  ...(!noMargins && {
    marginLeft: (typeof theme.sizes.pageMargins === 'number') ? theme.spacing(theme.sizes.pageMargins) : theme.sizes.pageMargins,
    marginRight: (typeof theme.sizes.pageMargins === 'number') ? theme.spacing(theme.sizes.pageMargins) : theme.sizes.pageMargins,
  }),
}))

export const Page: React.FC<React.PropsWithChildren<PageProps>> = (props) => {
    return <PageBox {...props} />
}

export default Page
