import { useCallback, useEffect, useMemo, useState } from "react"
import { FormikConfig, FormikHelpers } from "formik"
import { ClientError } from 'graphql/schema/graphql'
import { AxiosResponse } from "axios"
import { useAxios, UseAxiosConfig } from './useAxios'

export type OnSubmitFn<RData, RVariables, FValues = RVariables> = (fetchFunction: ReturnType<typeof useAxios<RData, RVariables>>['fetch'], values: FValues, formikHelpers: FormikHelpers<FValues>) => Promise<AxiosResponse<RData, FValues>>
export type OnSuccessFn<RData, RVariables, FValues = RVariables> = (data: RData, formikHelpers: FormikHelpers<FValues>) => void
export type OnErrorsFn<FValues> = (errors: ClientError[], formikHelpers: FormikHelpers<FValues>) => ClientError[] | false
export type CoerceFn<RVariables, FValues> = (values: FValues) => RVariables

export type AxiosFormConfig<RData, RVariables, FValues = RVariables> = Omit<Partial<FormikConfig<FValues>>, 'onSubmit'> & {
  coerce?: CoerceFn<RVariables, FValues>,
  onSubmit?: OnSubmitFn<RData, RVariables, FValues>,
  onSuccess?: OnSuccessFn<RData, RVariables, FValues>
  onErrors?: OnErrorsFn<FValues>
}

export type AxiosFormTuple<RData, RVariables, FValues = RVariables> = [
  FormikConfig<FValues>,
  Omit<ReturnType<typeof useAxios<RData, RVariables>>, 'fetch'> & {
    submitted?: boolean
  },
]

const defaultCoerce = <RVariables, FValues = RVariables>(values: FValues) => (values as unknown as RVariables)

export const useAxiosForm = <RData, RVariables, FValues = RVariables>(
  axiosRequestConfig?: UseAxiosConfig<RData, RVariables>,
  formConfig?: AxiosFormConfig<RData, RVariables, FValues>,
): AxiosFormTuple<RData, RVariables, FValues> => {
  const { initialValues, onSubmit, onSuccess, onErrors } = formConfig
  const coerce = (formConfig.coerce || defaultCoerce<RVariables, FValues>)

  const { fetch: fetchFunction, ...fetchResult } = useAxios<RData, RVariables>({ lazy: true, data: coerce(initialValues), ...axiosRequestConfig })

  const [ { formValues, formikHelpers }, setFormik ] = useState<{ formValues?: FValues, formikHelpers?: FormikHelpers<FValues> }>({})

  const [ executed, setExecuted ] = useState<boolean>(false)

  const submitFetchFunction = useCallback(async (values: FValues, formikHelpers: FormikHelpers<FValues>) => {
    setExecuted(false)
    setFormik({ formValues: values, formikHelpers })

    if (onSubmit === undefined) {
      return await fetchFunction({ data: coerce(values) })
    } else {
      return await onSubmit(fetchFunction, values, formikHelpers)
    }
  }, [ fetchFunction, coerce, onSubmit, setFormik, setExecuted ])

  const submitted = useMemo(() => {
    return fetchResult.called && !fetchResult.loading && fetchResult.data && fetchResult.errors.length === 0
  }, [ fetchResult ])

  const errors = useMemo(() => {
    return fetchResult?.errors.filter(error => !error.path || (formValues && !Object.keys(formValues).includes(error.path[error.path.length - 1])))
  }, [ fetchResult, formValues ])

  useEffect(() => {
    if (executed) {
      return
    } else {
      if (fetchResult.called && !fetchResult.loading) {
        if (fetchResult.errors.length > 0) {
          const mutationErrors = onErrors ? onErrors(fetchResult.errors, formikHelpers) : fetchResult.errors

          if (mutationErrors) {
            mutationErrors.forEach((error: ClientError) => {
              const fieldName = error.path && error.path[error.path.length - 1] as string

              if (fieldName && formValues) {
                if (Object.keys(formValues).includes(fieldName)) {
                  formikHelpers.setFieldError(fieldName, error.message)
                }
              }
            })
          }
        } else if (fetchResult.data) {
          if (onSuccess) onSuccess(fetchResult.data, formikHelpers)
        }
        setExecuted(true)
      }
    }
  }, [ fetchResult, formValues, formikHelpers, onSuccess, onErrors, executed, setExecuted ])

    return [ { onSubmit: submitFetchFunction, validationSchema: formConfig.validationSchema, initialValues }, { ...fetchResult, errors, submitted } ]
  }

export default useAxiosForm
