import React from 'react'
import MuiChip, { ChipProps } from '@mui/material/Chip'

export type { ChipProps }

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    plain: true
    branding: true
    campus: true
    kitchen: true
    kiosk: true
    luxo: true
    market: true
    tools: true
  }
}

export const Chip = <C extends React.ElementType='div'>(props: ChipProps<C, { component?: C }>) => <MuiChip {...props} />

export default Chip
