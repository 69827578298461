import React from 'react'
import { Button, ExtendedButtonProps } from 'components/Button'
import { SetFilters } from 'hooks/useFilters'
import { DateTime } from 'luxon'

export type DateButtonFilterProps<T extends object> = React.PropsWithChildren<{
  date: DateTime,
  newDate: DateTime,
  setFilters: SetFilters<T>
} & ExtendedButtonProps>


export function DateButtonFilter<T extends object>({
  children,
  date,
  newDate,
  setFilters,
  size="small",
  fullWidth=false,
  ...buttonProps
}: DateButtonFilterProps<T>) {
  const variant: "contained" | "text" = date.toFormat("y-LL-dd") === newDate.toFormat("y-LL-dd") ? "contained" : "text"

  return <Button
    variant={variant}
    size={size}
    fullWidth={fullWidth}
    onClick={() => setFilters({ date: newDate } as T)}
    {...buttonProps}
  >{children}</Button>
}

export default DateButtonFilter
