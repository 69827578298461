import { useCallback, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router'
import apolloClient from 'graphql/client'
import type { LocationWithFromPath } from 'types/routes'
import { useToken } from './useToken'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { useAxiosForm, AxiosFormConfig, AxiosFormTuple, OnSuccessFn, CoerceFn } from 'hooks/useAxiosForm'

export const zodSchema = z.object({
  email: z.string({
    required_error: 'Email is required',
  }).nonempty()
  .email('Email address is invalid'),
  password: z.string({
    required_error: 'Password is required',
  }).nonempty(),
})

export const validationSchema = toFormikValidationSchema(zodSchema)

type LoginResponseData = {
  token?: string
  refresh?: string
}

type LoginValues = {
  email: string
  password: string
}

type LoginVariables = {
  user: {
    email: string
    password: string
  }
}


export const useLoginForm = (config?: AxiosFormConfig<LoginResponseData, LoginVariables, LoginValues>): AxiosFormTuple<LoginResponseData, LoginVariables, LoginValues> => {
  const { token, valid, setToken } = useToken()
  const { resetStore } = apolloClient(token)
  const navigate = useNavigate()
  const location: LocationWithFromPath = useLocation()
  const from = location.state?.from?.pathname ? `${location.state?.from?.pathname}${location.state?.from?.search ? location.state?.from?.search : ''}` : '/'

  const onSuccess: OnSuccessFn<LoginResponseData, LoginVariables, LoginValues> = useCallback((data) => {
    if (data.token) {
      setToken(data.token, data.refresh)
      resetStore()
    }
  }, [setToken, resetStore])

  const coerce: CoerceFn<LoginVariables, LoginValues> = (values) => {
    return {
      user: {
        email: values.email,
        password: values.password,
      }
    }
  }

  const [ axiosForm, axiosResult] = useAxiosForm<LoginResponseData, LoginVariables, LoginValues>({
    url: '/users/sign_in',
    method: 'post',
  }, {
    ...config,
    coerce,
    onSuccess,
    initialValues: { email: '', password: '' }
  })

  useEffect(() => {
    if (valid) navigate(from, { replace: true })
  }, [valid, from, navigate])

  return [ { ...axiosForm, validationSchema }, axiosResult ]
}

export default useLoginForm
