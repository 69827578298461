import { enqueueSnackbar } from 'notistack'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { ChangePasswordMutationData, useChangePassword } from 'hooks/useChangePassword'
import { useMutationForm, MutationFormTuple, MutationFormConfig, OnSuccessFn } from 'hooks/useMutationForm'
import { ChangePasswordInput} from 'graphql/schema/graphql'

export const zodSchema = z.object({
  currentPassword: z.string({
    required_error: 'Current password is required',
  }).nonempty(),
  password: z.string({
    required_error: 'Password is required',
  })
    .nonempty()
    .min(6, 'Password must be at least 6 characters'),
  passwordConfirmation: z.string({
    required_error: 'Password Confirmation is required',
  })
    .nonempty()
    .min(6, 'Password Confirmation must be at least 6 characters'),
})

export const validationSchema = toFormikValidationSchema(zodSchema)

export const useChangePasswordForm = (config?: MutationFormConfig<ChangePasswordMutationData, ChangePasswordInput>): MutationFormTuple<ChangePasswordMutationData, ChangePasswordInput> => {
  const onSuccess: OnSuccessFn<ChangePasswordMutationData, ChangePasswordInput> = () => {
    enqueueSnackbar("Password changed", { variant: "success", autoHideDuration: 2000, preventDuplicate: true })
  }

  const [ mutationForm, mutationResult] = useMutationForm(useChangePassword, {
    ...config,
    onSuccess,
    initialValues: { currentPassword: '', password: '', passwordConfirmation: '' }
  })

  return [ { ...mutationForm, validationSchema }, mutationResult ]
}

export default useChangePasswordForm