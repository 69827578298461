import { useMemo } from 'react'
import { enqueueSnackbar } from 'notistack'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { useAxiosForm, AxiosFormConfig, AxiosFormTuple, OnSuccessFn, CoerceFn } from 'hooks/useAxiosForm'

export const zodSchema = z.object({
  email: z.string({
    required_error: 'Email is required',
  }).nonempty(),
})

export const validationSchema = toFormikValidationSchema(zodSchema)

type ForgotPasswordResponseData = {
  message?: string
}

type ForgotPasswordValues = {
  email: string
}

type ForgotPasswordVariables = {
  user: {
    email: string
  }
}

export const useForgotPasswordForm = (config?: AxiosFormConfig<ForgotPasswordResponseData, ForgotPasswordVariables, ForgotPasswordValues>): AxiosFormTuple<ForgotPasswordResponseData, ForgotPasswordVariables, ForgotPasswordValues> => {
  const metaBasename: HTMLMetaElement = document.head.querySelector('meta[name="basename"]')
  const basename: string | undefined = useMemo(() => metaBasename ? metaBasename.content : undefined, [metaBasename])
  const url: string = useMemo(() => basename && basename !== '/' ? `${basename}/users/password` : '/users/password' , [basename])

  const onSuccess: OnSuccessFn<ForgotPasswordResponseData, ForgotPasswordVariables, ForgotPasswordValues> = () => {
    enqueueSnackbar("Password reset email sent", { variant: "success", autoHideDuration: 3000, preventDuplicate: true })
  }

  const coerce: CoerceFn<ForgotPasswordVariables, ForgotPasswordValues> = (values) => {
    return {
      user: {
        email: values.email
      }
    }
  }

  const [ axiosForm, axiosResult] = useAxiosForm<ForgotPasswordResponseData, ForgotPasswordVariables, ForgotPasswordValues>({
    url,
    method: 'post',
  }, {
    ...config,
    onSuccess,
    coerce,
    initialValues: { email: '' }
  })

  return [ { ...axiosForm, validationSchema }, axiosResult ]
}

export default useForgotPasswordForm