import React, { useCallback } from 'react'
import {
  Box,
  Text,
  TextField,
} from 'components'
import type { TextFieldProps } from 'components/TextField'
import { SetFilters, ApplyFilters, SetAndApplyFilters, Filters, FilterOnChange } from 'hooks/useFilters'

export interface TextFieldFilterProps<T extends object, D extends object, K extends keyof T = keyof T> {
  filterName: string,
  filterDefinitions: Filters<T, D, K>,
  filters: T,
  liveFilter?: boolean
  simpleField?: boolean
  setFilters: SetFilters<T>
  applyFilters: ApplyFilters
  setAndApplyFilters: SetAndApplyFilters<T>
}

export function TextFieldFilter<T extends object, D extends object, K extends keyof T = keyof T>(props: TextFieldFilterProps<T, D, K> & Omit<TextFieldProps, 'label' | 'value' | 'onChange' | 'onKeyDown'>) {
  const { simpleField, filterName, filterDefinitions, filters, liveFilter, setFilters, applyFilters, setAndApplyFilters, variant="outlined", ...textFieldProps } = props
  const filterDefinition = filterDefinitions.find((definition) => definition.name === filterName)

  const onChange: FilterOnChange<object> = useCallback((value) => {
    return { [filterName]: value }
  }, [filterName])

  const onFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilters((filterDefinition.onChange || onChange)(event.target.value, filters))
    if (liveFilter) {
      // eslint-disable-next-line prefer-const
      let timer: number
      window.clearTimeout(timer)
      timer = window.setTimeout(() => setAndApplyFilters((filterDefinition.onChange || onChange)(event.target.value, filters)), 200)
    }

  }, [liveFilter, filters, filterDefinition.onChange, onChange, setFilters, setAndApplyFilters])

  const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault()
      event.stopPropagation()
      applyFilters()
    }
  }, [applyFilters])

  if (simpleField) {
    return <TextField
      {...textFieldProps}
      inputProps={Object.assign({ autoComplete: 'off', ...textFieldProps.inputProps })}
      variant={variant}
      label={filterDefinition.label}
      value={filters[filterName] || ''}
      onChange={onFieldChange}
      onKeyDown={onKeyDown}
    />
  } else {
    return <Box width="100%" pb={4}>
      <Text variant="subtitle1" fontWeight="medium" pb={2}>{ filterDefinition.label }</Text>

      <TextField
        {...textFieldProps}
        inputProps={Object.assign({ autoComplete: 'off', ...textFieldProps.inputProps })}
        variant={variant}
        label={filterDefinition.label}
        value={filters[filterName] || ''}
        onChange={onFieldChange}
        onKeyDown={onKeyDown}
      />
    </Box>
  }
}

export default TextFieldFilter
