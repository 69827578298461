import React from 'react'
import { Checkbox, CheckboxProps } from 'components/Checkbox'
import { FormControlLabel, FormControlLabelProps } from '../FormControlLabel'
import FormField, { FieldProps } from '../FormField'

type FormCheckboxProps = Partial<FieldProps> & CheckboxProps & Omit<FormControlLabelProps, 'control'>

export const FormCheckbox: React.FC<FormCheckboxProps> = ({ name, value, ...props }) => {
  return (
    <FormField
      type="checkbox"
      name={name}
      value={value}
      component={({ field }) => {
        return (
          <FormControlLabel
            {...props}
            control={
              <Checkbox
                {...field}
              />
            }
          />
        )
      }}
    />
  )
}

export default FormCheckbox
