import { gql } from 'graphql/schema/gql'

export const meQuery = gql(`
  query meQuery {
    me {
      ...meFragment
    }
  }
`)

export default meQuery
