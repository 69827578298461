import { useCallback, useMemo } from 'react'
import { enqueueSnackbar } from 'notistack'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import { useAxiosForm, AxiosFormConfig, AxiosFormTuple, OnSuccessFn, CoerceFn } from './useAxiosForm'
import { useQueryParams } from './useQueryParams'

export const zodSchema = z.object({
  password: z.string({
    required_error: 'Password is required',
  })
  .nonempty()
  .min(6, 'Password must be at least 6 characters'),
  passwordConfirmation: z.string({
    required_error: 'Password Confirmation is required',
  })
  .nonempty()
  .min(6, 'Password Confirmation must be at least 6 characters'),
})

export const validationSchema = toFormikValidationSchema(zodSchema)

type ResetPasswordResponseData = {
  message?: string
}

type ResetPasswordValues = {
  resetPasswordToken: string
  password: string
  passwordConfirmation: string
}

type ResetPasswordVariables = {
  user: {
    reset_password_token: string
    password: string
    password_confirmation: string
  }
}

export const useResetPasswordForm = (config?: AxiosFormConfig<ResetPasswordResponseData, ResetPasswordVariables, ResetPasswordValues>): AxiosFormTuple<ResetPasswordResponseData, ResetPasswordVariables, ResetPasswordValues> => {
  const { reset_password_token } = useQueryParams<{ reset_password_token?: string }>({ reset_password_token: undefined })
  const metaBasename: HTMLMetaElement = document.head.querySelector('meta[name="basename"]')
  const basename: string | undefined = useMemo(() => metaBasename ? metaBasename.content : undefined, [metaBasename])
  const url: string = useMemo(() => basename && basename !== '/' ? `${basename}/users/password` : '/users/password' , [basename])

  const onSuccess: OnSuccessFn<ResetPasswordResponseData, ResetPasswordVariables, ResetPasswordValues> = () => {
    enqueueSnackbar("Password changed successfully", { variant: "success", autoHideDuration: 3000, preventDuplicate: true })
  }

  const coerce: CoerceFn<ResetPasswordVariables, ResetPasswordValues> = useCallback((values) => {
    return {
      user: {
        reset_password_token,
        password: values.password,
        password_confirmation: values.passwordConfirmation,
      }
    }
  }, [ reset_password_token ])

  const [ axiosForm, axiosResult] = useAxiosForm<ResetPasswordResponseData, ResetPasswordVariables, ResetPasswordValues>({
    url: url,
    method: 'put',
  }, {
    ...config,
    onSuccess,
    coerce,
    initialValues: { resetPasswordToken: reset_password_token, password: '', passwordConfirmation: '' }
  })

  return [ { ...axiosForm, validationSchema }, axiosResult ]
}

export default useResetPasswordForm