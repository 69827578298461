import React from 'react'
import { Box, Text, Button } from 'components'
import { Add as AddIcon } from 'icons'
import { Document, SelectOption } from 'graphql/schema/graphql'
import { DocumentsList } from './DocumentsList'

export type DocumentsProps = { documents: Document[], documentTypeOptions: SelectOption[], onClickAddDocument: () => void }

export const Documents: React.FC<React.PropsWithChildren<DocumentsProps>> = ({ children, documents, documentTypeOptions, onClickAddDocument }) => {
  return <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 2 }}>
      <Box>
        <Text variant="h4" mb={2}>Manage Secure Documents</Text>
        <Text mb={2}>Upload or review your important documents.</Text>
      </Box>

      <Box>
        <Button variant="contained" color="plain" fullWidth={false} startIcon={<AddIcon />} onClick={onClickAddDocument}>
            Add document
        </Button>
      </Box>
    </Box>

    { children }

    <DocumentsList documents={documents} documentTypeOptions={documentTypeOptions} />
  </Box>
}

export default Documents
