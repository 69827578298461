import { gql } from 'graphql/schema/gql'

export const changePasswordMutation = gql(`
  mutation changePasswordMutation($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      token
      refreshToken
      errors {
        code
        message
      }
    }
  }
`)

export default changePasswordMutation
