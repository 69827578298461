import React, { useMemo } from 'react'
import { getIn, useFormikContext } from 'formik'
import { Alert, Box, Form, FormDatePicker, FormFileInput, FormSelect, FormSubmitButton } from 'components'
import { MutationFormTuple } from 'hooks/useMutationForm'
import { SelectOption } from 'graphql/schema/graphql'
import { DateTime } from 'luxon'

const DocumentTypeInput: React.FC<{ documentTypeOptions: SelectOption[], withoutExpiration?: string[] }> = ({ documentTypeOptions, withoutExpiration, ...props }) => {
  const { values, setFieldValue } = useFormikContext()

  return <FormSelect
    {...props}
    id="type"
    name="type"
    label="Document type"
    options={documentTypeOptions}
    onChange={() => {
      if (getIn(values, 'type') && withoutExpiration?.includes(getIn(values, 'type')))
        setFieldValue('expiresAt', undefined)
    }}
  />
}

const ExpiresAtInput: React.FC<{ withoutExpiration?: string[] }> = ({ withoutExpiration, ...props }) => {
  const { values } = useFormikContext()
  const disabled = getIn(values, 'type') && withoutExpiration?.includes(getIn(values, 'type'))

  return <FormDatePicker
    {...props}
    id="expiresAt"
    name="expiresAt"
    label="Expiration date (optional)"
    minDate={DateTime.now()}
    disabled={disabled}
    helperText={disabled ? "This document will expire at the end of your configured school year" : undefined}
  />
}

export interface UploadDocumentFormProps {
  form: MutationFormTuple<object, object>
  documentTypeOptions: SelectOption[]
  withoutExpiration?: string[]
}

export const UploadDocumentForm: React.FC<UploadDocumentFormProps> = ({ form, documentTypeOptions, withoutExpiration=[] }) => {
  const [ mutationForm, { errors, loading, submitted } ] = form

  // NOTE: This triggers the file input to re-render after submission via `key` prop, since the value isn't passed into it directly
  const submittedAt = useMemo(() => {
    if (submitted && !loading) return DateTime.now()
  }, [ submitted, loading ])

  return <Box>
    <Form form={mutationForm}>
      <Box display="flex" flexDirection="row" justifyContent="flex-start" alignContent="flex-start">
        <Box flexGrow={1} mr={2}>
          <DocumentTypeInput documentTypeOptions={documentTypeOptions} withoutExpiration={withoutExpiration} />
        </Box>

        <Box flexGrow={1}>
          <ExpiresAtInput withoutExpiration={withoutExpiration} />
        </Box>
      </Box>

      <FormFileInput
        key={submittedAt?.toString()}
        id="file"
        name="file"
        label="Upload file"
        pl={14}
      />

      { !submitted && <>{ errors.map((error) => <Alert key={`${error.code}:${error.message}`} severity="error" sx={{ width: '100%', mb: 2 }}>
        {error.message}
      </Alert>)}</> }

      <FormSubmitButton
        loading={loading}
      >
        Upload document
      </FormSubmitButton>
    </Form>
  </Box>
}

export default UploadDocumentForm
