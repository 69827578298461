import { gql } from 'graphql/schema/gql'

export const statesProvincesQuery = gql(`
  query statesProvincesQuery {
    statesProvinces {
      id
      name
      abbreviation
    }
  }
`)

export default statesProvincesQuery
