import React from 'react'
import { Box, BoxProps } from '../Box'
import styled from '@mui/material/styles/styled'

export type PageContentProps = BoxProps & { unpadded?: boolean }

export const PageContentBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'unpadded' })<PageContentProps>(({ theme, unpadded }) => ({
  width: '100%',
  flexGrow: 1,
  overflow: 'auto',
  ...(!unpadded && {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  }),
  [theme.breakpoints.up('sm')]: {
    ...(!unpadded && {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    }),
  },
  [theme.breakpoints.up('md')]: {
    ...(!unpadded && {
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    }),
  },
}))

export const PageContent: React.FC<React.PropsWithChildren<PageContentProps>> = (props) => {
    return <PageContentBox {...props} />
}

export default PageContent
