import React from 'react'
import {
  PopoverContent,
  PopoverContentProps,
} from 'components/Popover'
import {
  DialogContent,
  DialogContentProps,
} from 'components/Dialog'
import { useViewport } from 'hooks'

export type FiltersPopoverContentProps = PopoverContentProps & DialogContentProps

export const FiltersPopoverContent: React.FC<FiltersPopoverContentProps> = (props) => {
  const { isMedium} = useViewport()

  if (isMedium) {
    return <PopoverContent {...props as PopoverContentProps} />
  } else {
    return <DialogContent {...props as DialogContentProps} />
  }
}

export default FiltersPopoverContent
