/* eslint-disable react/no-unescaped-entities */
import React, { PropsWithChildren } from 'react'
import { Link as RouterLink, Navigate, useLocation } from 'react-router-dom'
import { useToken } from 'hooks'
import { Box, Text, Link } from 'components'
import { LocationWithFromPath } from 'types/routes'


export const Authenticated: React.FC<PropsWithChildren<{ redirect?: boolean }>> = ({ redirect=true, children }) => {
  const { token, refresh, refreshing, refreshToken } = useToken()
  const auth = !!token || !!refreshing
  const location: LocationWithFromPath = useLocation()
  const onClickRefresh = (e: React.MouseEvent) => {
    e.preventDefault()
    refreshToken()
  }

  if (!auth) {
    if (!!refresh) {
      return <Box maxWidth={[1]} mx="auto">
        <Text variant="h2">Your session has expired</Text>
        <Text component="p">Please <Link component={RouterLink} to="/login" state={{ from: location }} onClick={onClickRefresh}>click here to continue</Link> (you may be asked to login again)</Text>
      </Box>
    } else {
      return redirect ? <Navigate to="/login" state={{ from: location }} replace /> : <Box maxWidth={[1]} mx="auto">
        <Text variant="h2">You must login to continue</Text>
        <Text component="p"><Link component={RouterLink} to="/login" state={{ from: location }}>Click here to login</Link>, or if you don't have an account, <Link component={RouterLink} to="/register">click here to sign up</Link></Text>
      </Box>
    }
  } else {
    return <>{children}</>
  }
}

export const Unauthenticated: React.FC<PropsWithChildren<{ redirect?: boolean }>> = ({ redirect=true, children }) => {
  const { token } = useToken()
  const auth = !!token
  const location = useLocation()

  if (auth) {
    return redirect ? <Navigate to="/" /> : <Box maxWidth={[1]} mx="auto">
      <Text variant="h2">You are already logged in</Text>
      <Text component="p"><Link component={RouterLink} to="/logout" state={{ from: location }}>Click here to logout</Link>, or <Link component={RouterLink} to="/">click here return home</Link></Text>
    </Box>
  } else {
    return <>{children}</>
  }
}

export default Authenticated
