import React from 'react'
// import { Link as RouterLink } from 'react-router-dom'
import { styled } from '@mui/material'
// import MenuItem from '@mui/material/MenuItem'
import Drawer, { DrawerProps } from '@mui/material/Drawer'
import MenuList from '@mui/material/MenuList'
import Toolbar from '@mui/material/Toolbar'
// import { Login as LoginIcon } from 'icons'
import { useMe, useViewport } from 'hooks'
import {
  Box,
  // Link,
  // Text,
  // MenuLink
} from 'components'
import Footer from './Footer'
import { Account, SchoolLocation, Term, VendorLocation } from 'graphql/schema/graphql'
import { AppName } from 'hooks/useBaseUrl'

export type NavDrawerProps = {
  appName: AppName
  toggleDrawer: (open: boolean) => void
  accountMenuOpen: boolean
  setAccountMenuOpen: (open: boolean) => void
  AccountMenuItems?: React.FC<{ me: ReturnType<typeof useMe> }>
  NavigationMenuItems?: React.FC<{ me: ReturnType<typeof useMe> }>
  currentLocation?: SchoolLocation | VendorLocation
  currentTerm?: Term
  currentAccount?: Account
  anchor?: "bottom" | "left" | "right" | "top"
} & DrawerProps

type DrawerContainerProps = Pick<NavDrawerProps, "appName" | "sx" | "toggleDrawer" | "anchor" | "variant" | "AccountMenuItems" | "NavigationMenuItems" | "currentAccount" | "currentLocation" | "currentTerm" | "accountMenuOpen" | "setAccountMenuOpen" | "open">

const NavigationMenu = styled(MenuList)(({ theme }) => ({
  ['.MuiMenuItem-root']: {
    paddingLeft: theme.spacing(6),
  },
  ['a']: {
    width: '100%'
  }
}))

const DrawerContainer: React.FC<React.PropsWithChildren<DrawerContainerProps>> = ({ appName, open, anchor, variant, currentAccount, currentLocation, currentTerm, NavigationMenuItems, AccountMenuItems, accountMenuOpen, toggleDrawer, setAccountMenuOpen, children, ...props }) => {
  const me = useMe()
  const { token, parsed, me: user } = me

  const onMenuClick: React.MouseEventHandler = () => {
    if (variant === "temporary") toggleDrawer(false)
  }

  if (!token) return null
  if (variant === "persistent" && !open) return null

  return <Drawer {...props} anchor={anchor} open={open} variant={variant} onClose={() => toggleDrawer(false)}>
    <Toolbar />

    <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
      <Box>
        <NavigationMenu onClick={onMenuClick}>
          { (token && user && user.id === parsed?.user_id) && NavigationMenuItems && <NavigationMenuItems me={me} /> }

          {/* { !token &&
            <MenuItem>
              <MenuLink component={RouterLink} to="login">
                <LoginIcon sx={{ marginRight: 1 }} />
                Login
              </MenuLink>
            </MenuItem>
          } */}
        </NavigationMenu>
      </Box>

      <Box>
        { (token && user && user.id === parsed?.user_id) && children }
        { (token && user && user.id === parsed?.user_id) && <Footer appName={appName} me={me} currentAccount={currentAccount} currentLocation={currentLocation} currentTerm={currentTerm} onClick={onMenuClick} AccountMenuItems={AccountMenuItems} accountMenuOpen={accountMenuOpen} setAccountMenuOpen={setAccountMenuOpen} /> }
      </Box>
    </Box>
  </Drawer>
}

export const NavDrawer: React.FC<NavDrawerProps> = (props) => {
  const { isLaptop } = useViewport()

  return <>
    <DrawerContainer {...props} variant={isLaptop ? "persistent" : "temporary"} />
  </>
}

export default NavDrawer