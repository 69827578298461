import React from 'react'
import MuiCircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress'
import styled from 'styled-components'
import Box from '../Box'

type ExtendedCircularProgressProps = CircularProgressProps & {
  size: number;
  message: React.ReactNode;
  inline?: 'left' | 'center' | 'right';
};

// TODO Figure out best way to move these styles to global theme

const SpinWrapper = styled(Box)({
  display: 'flex',
  flex: '1 auto',
  height: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
})

const SpinWrapperInline = styled(SpinWrapper)({
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  gap: '1rem'
})

const SpinWrapperInlineCenter = styled(SpinWrapperInline)({
  justifyContent: 'center'
})

const SpinWrapperInlineRight = styled(SpinWrapperInline)({
  justifyContent: 'flex-end'
})

const Spinner = ({ message, inline, ...props }: ExtendedCircularProgressProps) => {
  let SpinWrapperEl = SpinWrapper
  if (inline) {
    switch (inline) {
      case 'left':
        SpinWrapperEl = SpinWrapperInline
        break
      case 'center':
        SpinWrapperEl = SpinWrapperInlineCenter
        break
      case 'right':
        SpinWrapperEl = SpinWrapperInlineRight
        break
      default:
        break
    }
  }

  return (
    <SpinWrapperEl>
      <MuiCircularProgress {...props} />
      {message}
    </SpinWrapperEl>
  )
}

Spinner.defaultProps = {
  size: 30,
  inline: null,
}

export default Spinner
