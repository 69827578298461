import React from 'react'
import Markdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { Stepper as MuiStepper, Step, StepLabel, StepContent, styled, StepIconProps } from '@mui/material'
import { Box, Link, Text } from 'components'
import { TodosWizard } from 'hooks/useTodosWizard'
import { StepIcon } from './StepIcon'

const StepLabelText = styled(Text)<{
  ownerState: { completed?: boolean; active?: boolean, ignored?: boolean, resolved?: boolean };
}>(({ theme, ownerState }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'normal',
  ...(ownerState.ignored && {
    color: '#ccc',
  }),
  ...(ownerState.active && {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  }),
  ...(ownerState.resolved && {
    color: theme.palette.success.light,
  }),
  ...(ownerState.completed && {
    color: theme.palette.success.main,
  }),
}))

const TodoStepper = styled(MuiStepper)((/*{ theme }*/) => ({
  ".MuiStepLabel-root": {
    flexDirection: "row-reverse",
    ".MuiStepLabel-labelContainer": {
      textAlign: 'right',
    },
    ".MuiStepLabel-iconContainer": {
      paddingLeft: 8,
    },
  },
  ".MuiStepContent-root": {
    marginLeft: 0,
    marginRight: 16,
    paddingRight: 16,
    paddingLeft: 8,
    borderLeft: "none",
    borderRight: "1px solid rgb(189, 189, 189)"
  },
  ".MuiStepConnector-vertical": {
    marginLeft: 0,
    marginRight: 16,
    ".MuiStepConnector-lineVertical": {
      borderLeftStyle: "none",
      borderLeftWidth: 0,
      borderRightStyle: "solid",
      borderRightWidth: 1,
    },
  },
}))

export const Stepper: React.FC<{ wizard: TodosWizard }> = ({ wizard }) => {
  const { id } = useParams()

  return <TodoStepper activeStep={wizard.activeStep} orientation="vertical" nonLinear sx={{ width: 400 }}>
    { wizard.todos?.map((todo) => {
      return <Step key={todo.id} completed={todo.status === "completed"}>
        {/* TODO: figure out a better way to not have to cast props */}
        <StepLabel StepIconComponent={StepIcon} StepIconProps={{ todo } as unknown as StepIconProps}>
          <Link to={`/todos/${todo.id}`} component={RouterLink}>
            <StepLabelText variant="h6" ownerState={{ completed: todo.status === "completed", ignored: todo.status === "ignored", active: todo.id === id, resolved: todo.resolved }}>{todo.name}</StepLabelText>
          </Link>
        </StepLabel>
        <StepContent>
          <Box mb={2} textAlign="right">
            { todo.summary && <Markdown rehypePlugins={[rehypeRaw]}>{ todo.summary }</Markdown> }
          </Box>
        </StepContent>
      </Step>
    }) }
  </TodoStepper>
}

export default Stepper
