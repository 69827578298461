import { gql } from 'graphql/schema/gql'

export const locationDocumentsQuery = gql(`
  query locationDocumentsQuery($locationId: ID) {
    locationDocuments(locationId: $locationId) {
      ...documentFragment
    }
  }
`)

export default locationDocumentsQuery
