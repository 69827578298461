import { useEffect } from 'react'
import { changePasswordMutation } from 'graphql/mutations'
import { useToken } from './useToken'
import { MutationTuple, useMutation } from './useMutation'
import type { ChangePasswordInput, ChangePasswordPayload } from 'graphql/schema/graphql'
export type { ChangePasswordInput }

export type ChangePasswordMutationData = { changePassword: ChangePasswordPayload }

export const useChangePassword: (variables?: ChangePasswordInput) => MutationTuple<ChangePasswordMutationData, ChangePasswordInput> = (variables)  => {
  const { setToken } = useToken()

  const [ changePasswordFn, { data, ...result } ] = useMutation<ChangePasswordMutationData, ChangePasswordInput>(
    changePasswordMutation,
    {
      variables,
      // notifyOnNetworkStatusChange: true
    }
  )

  useEffect(() => {
    if (data?.changePassword?.token && data?.changePassword?.refreshToken) {
      setToken(data.changePassword.token, data.changePassword.refreshToken)
    }
  }, [ data, setToken ])

  return [ changePasswordFn, { ...result, data } ]
}

export default useChangePassword
