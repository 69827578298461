import React, { useCallback, useMemo } from 'react'
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, TextField } from 'components'
import { Close as CloseIcon } from 'icons'
import { useViewport } from 'hooks'
import { DialogProps } from '@mui/material/Dialog'
import { HelpDialogOption, HelpOption, onSelectFn } from './HelpDialogOption'
import { ClientError } from 'graphql/schema/graphql'

export type HelpRequestState = {
  requestId?: string
  selected?: string
  notes?: string
  errors?: ClientError[]
}

export type HelpDialogProps = {
  options: HelpOption[]
  selected?: HelpOption
  notes?: string
  loading: boolean
  requestId?: string
  errors?: ClientError[]
  onClose: () => void
  onChange: (state: HelpRequestState) => void
  onSubmit: () => void
  SuccessMessage: React.FC<{ requestId: string }>
  ErrorMessage: React.FC
} & Omit<DialogProps, 'onChange'>

export const HelpDialog: React.FC<HelpDialogProps> = ({ open, options, selected, notes, loading, requestId, errors, SuccessMessage, ErrorMessage, onChange, onClose, onSubmit }) => {
  const { isTablet } = useViewport()
  const enableSubmit: boolean = useMemo(() => selected && (!selected.notesRequired || (notes && notes.length > 0)), [ selected, notes ])
  const onSelectOption: onSelectFn = useCallback((type) => onChange({ selected: type }), [ onChange ])
  const onChangeNotes = useCallback((evt: React.BaseSyntheticEvent) => onChange({ notes: evt.target.value }), [ onChange])

  return <Dialog fullScreen={!isTablet} open={open} onClose={onClose}>
    <DialogTitle sx={{ textAlign: 'center' }}>
      { !requestId ? "Request Help" : "Request Submitted" }
      <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
      </IconButton>
    </DialogTitle>

    <DialogContent>
      <Box display="flex" flexDirection="column" rowGap={2} width="100%" minWidth={{ md: '400px' }}>
        { !!requestId && <>
          <Box>
            <SuccessMessage requestId={requestId} />
          </Box>

          <Box>
            <Button onClick={onClose} variant="text" color="inherit">Close</Button>
          </Box>
        </> }

        { !requestId && errors.length > 0 && <>
          <Box>
            <ErrorMessage />
          </Box>

          <Box>
            <Button onClick={onClose} variant="text" color="inherit">Close</Button>
          </Box>
        </> }

        { !requestId && errors.length === 0 && <>
          <Box display="flex" flexDirection="column" rowGap={1} width="100%">
            { options.map((option) =>
              <HelpDialogOption key={option.type} disabled={loading} type={option.type} name={option.name} active={selected?.type === option.type} onSelect={onSelectOption} />
            ) }
          </Box>

          { selected && <Box>
            <TextField fullWidth multiline disabled={loading} type="textarea" minRows={3} label={selected.notesRequired ? "Notes (Required)" : "Notes (Optional)"} placeholder="Please provide us with details about your request" value={notes} onChange={onChangeNotes} />
          </Box> }

          <Box>
            <Button disabled={!enableSubmit} loading={loading} onClick={onSubmit}>Submit Help Request</Button>
          </Box>
        </> }
      </Box>
    </DialogContent>
  </Dialog>
}

export default HelpDialog
