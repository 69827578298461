import React, { useCallback } from 'react'
import {
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  Text,
} from 'components'
import { SetFilters, Filters, FilterOnChange } from 'hooks/useFilters'

export interface RadioFilterProps<T extends object, D extends object, K extends keyof T = keyof T> {
  filterName: string,
  filterDefinitions: Filters<T, D, K>,
  filters: T,
  row?: boolean
  setFilters: SetFilters<T>
}

export function RadioFilter<T extends object, D extends object, K extends keyof T = keyof T>(props: RadioFilterProps<T, D, K>) {
  const { filterName, filterDefinitions, filters, row, setFilters } = props
  const filterDefinition = filterDefinitions.find((definition) => definition.name === filterName)

  const onChange: FilterOnChange<object> = useCallback((value, filters, checked) => {
    const withoutOption = filters[filterName].filter((val: string) => val !== value)
    if (checked) {
      return { [filterName]: [ value ] }
    } else {
      return { [filterName]: withoutOption }
    }
  }, [filterName])


  return <Box width="100%" pb={4}>
    <Text variant="subtitle1" fontWeight="medium" pb={2}>{ filterDefinition.label }</Text>

    { (!filterDefinition.options || filterDefinition.options.length === 0) && <Text variant="caption">No filter options found in the current data set. Try expanding your other filters.</Text> }
    <RadioGroup row={row}>
      { filterDefinition.options?.map((option) =>
        <FormControlLabel key={option.value} label={option.key} control={
          <Radio checked={filters[filterName].includes(option.value)} onChange={(_e, checked) => {
            setFilters((filterDefinition.onChange || onChange)(option.value, filters, checked))
          }} />
        } />
      ) }
    </RadioGroup>
  </Box>
}


export default RadioFilter
