import Message from '@mui/material/Alert'
import MessageTitle from '@mui/material/AlertTitle'

export type { AlertProps as MessageProps } from '@mui/material/Alert'

export {
  Message,
  MessageTitle,
}

export default Message
