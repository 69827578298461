import React from 'react'
import { TextField, TextFieldProps } from 'components/TextField'
import FormField, { FieldProps } from '../FormField'
import { getIn } from 'formik'

type FormTextFieldProps = Partial<FieldProps> & TextFieldProps

export const FormTextField: React.FC<FormTextFieldProps> = ({ name, helperText, autoComplete="off", InputLabelProps, ...props }) => {
  return (
    <FormField
      name={name}
      component={({ form, field, ...rest }) => {
        const { touched, errors } = form
        return (
          <TextField
            {...props}
            {...rest}
            autoComplete={autoComplete}
            error={Boolean(getIn(touched, name) && getIn(errors, name))}
            helperText={((getIn(touched, name) && getIn(errors, name)) || helperText)}
            InputLabelProps={{ shrink: true, ...InputLabelProps }}
            {...field}
          />
        )
      }}
    />
  )
}

export default FormTextField
