import { useContext } from 'react'
import { ApplicationContext } from 'compositions/Application'
import type { GlobalContext } from 'compositions/Application'

export { GlobalContext }

export const useGlobalContext = (): GlobalContext => {
  const globalContext = useContext<GlobalContext>(ApplicationContext)
  return globalContext
}

export default useGlobalContext
