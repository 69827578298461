import React from 'react'
import {
  Box,
  Button,
} from 'components'
import {
  PopoverHeader,
  PopoverHeaderProps,
} from 'components/Popover'
import {
  DialogTitle,
  DialogTitleProps,
} from 'components/Dialog'
import { useViewport } from 'hooks'
import { ArrowBack } from 'icons'

export type FiltersPopoverHeaderProps = PopoverHeaderProps & DialogTitleProps
export const FiltersPopoverHeader: React.FC<FiltersPopoverHeaderProps> = ({ children, ...props }) => {
  const { isMedium} = useViewport()

  if (isMedium) {
    return <PopoverHeader {...props as PopoverHeaderProps}>
      { children }
    </PopoverHeader>
  } else {
    return <DialogTitle {...props as DialogTitleProps}>
      <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
        <Box>
          <Button
            aria-label="close"
            onClick={props.onClose}
            variant="text"
          >
            <ArrowBack />
          </Button>
        </Box>

        <Box flexGrow={1}>
          { children }
        </Box>
      </Box>
    </DialogTitle>
  }
}

export default FiltersPopoverHeader
