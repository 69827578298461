import { ClientError } from 'graphql/schema/graphql'
import type {
  ApolloCache,
  DefaultContext,
  DocumentNode,
  TypedDocumentNode,
  MutationHookOptions,
  MutationFunctionOptions,
  MutationResult as ApolloMutationResult,
  FetchResult,
  OperationVariables,
} from "@apollo/client"
import { useMutation as useApolloMutation } from "@apollo/client"

export type MutationInputVariables<TVariables> = { input?: TVariables }

export interface MutationResult<TData = unknown> extends ApolloMutationResult {
  data?: TData | null
  errors: ClientError[]
}

export type MutationTuple<TData, TVariables, TContext = DefaultContext, TCache extends ApolloCache<unknown> = ApolloCache<unknown>> = [
  (options?: MutationFunctionOptions<TData, TVariables, TContext, TCache>) => Promise<FetchResult<TData>>,
  MutationResult<TData>
]

export const useMutation = <TData = unknown, TVariables = OperationVariables, TContext = DefaultContext, TCache extends ApolloCache<unknown> = ApolloCache<unknown>>(
  mutation: DocumentNode | TypedDocumentNode<TData, MutationInputVariables<TVariables>>,
  options?: MutationHookOptions<TData, TVariables, TContext>
): MutationTuple<TData, TVariables, TContext, TCache> => {

  const mutationOptions = options ? { ...options, variables: { input: options?.variables } } : options
  const [ mutationFunction, { data, error, ...other } ] = useApolloMutation<TData, MutationInputVariables<TVariables>, TContext, TCache>(mutation, mutationOptions)

  let errors: ClientError[] = []

  if (data) {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const mutationResult = data[key]
        if (mutationResult['errors'])
          errors = errors.concat(mutationResult['errors'])
      }
    }
  }

  const mutationInputFunction = (options?: MutationFunctionOptions<TData, TVariables, TContext, TCache>) => {
    if (options) {
      return mutationFunction({ ...options, variables: { input: options?.variables }})
    } else {
      return mutationFunction()
    }
  }

  return [ mutationInputFunction, { ...other, data, error, errors } ]
}

export default useMutation
