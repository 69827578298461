export type AppName = 'market' | 'campus' | 'kitchen' | 'kiosk' | 'luxo'

export const AppDomainRegex = /(tools|app|campus|schools|kitchen|chefs|kiosk|admin|luxo)\./

export const AppDomains = {
  market: 'app',
  campus: 'schools',
  kitchen: 'chefs',
  kiosk: 'kiosk',
  luxo: 'luxo',
}
export const AppBasePaths = {
  market: 'app',
  campus: 'schools',
  kitchen: 'chefs',
  kiosk: 'kiosk',
  luxo: 'luxo',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useBaseUrl = (appName: AppName): string => {
  // const metaBasename: HTMLMetaElement = document.head.querySelector('meta[name="basename"]')
  // const basename: string | undefined = metaBasename ? metaBasename.content : undefined

  // if (basename === undefined) {
  //   return window.location.origin.replace(AppDomainRegex, `${AppDomains[appName]}.`)
  // } else {
  //   return `${window.location.origin}/${AppBasePaths[appName]}`
  // }

  return window.location.origin.replace(AppDomainRegex, `${AppDomains[appName]}.`)
}

export default useBaseUrl