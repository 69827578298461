import React from 'react'
import { Alert, Form, FormTextField, FormSubmitButton } from 'components'
import { MutationFormTuple } from 'hooks/useMutationForm'
import { ChangePasswordInput, ChangePasswordMutationData } from 'hooks/useChangePassword'

export const ChangePasswordForm: React.FC<{ form: MutationFormTuple<ChangePasswordMutationData, ChangePasswordInput> }> = ({ form }) => {
  const [ mutationForm, { errors, loading, submitted } ] = form

  return (
    <Form form={mutationForm}>

      <FormTextField
        id="currentPassword"
        name="currentPassword"
        label="Current password"
        type="password"
      />
      <FormTextField
        id="password"
        name="password"
        label="New password"
        type="password"
      />
      <FormTextField
        id="passwordConfirmation"
        name="passwordConfirmation"
        label="New password confirmation"
        type="password"
      />

      { !submitted && !loading && <>{ errors.map((error) => <Alert key={`${error.code}:${error.message}`} severity="error" sx={{ width: '100%', mb: 2 }}>
        {error.message}
      </Alert>)}</> }

      <FormSubmitButton
        loading={loading}
      >
        Change password
      </FormSubmitButton>
    </Form>
  )
}

export default ChangePasswordForm
