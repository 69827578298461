import React from 'react'
import {
  Popover,
  PopoverProps,
} from 'components/Popover'
import {
  Dialog,
  DialogProps,
} from 'components/Dialog'
import { useViewport } from 'hooks'

export type FiltersPopoverProps = PopoverProps & DialogProps

export const FiltersPopover: React.FC<FiltersPopoverProps> = ({ anchorEl, anchorOrigin, anchorPosition, anchorReference, ...props }) => {
  const { isMedium} = useViewport()

  if (isMedium) {
    return <Popover {...props as PopoverProps} anchorEl={anchorEl} anchorOrigin={anchorOrigin} anchorPosition={anchorPosition} anchorReference={anchorReference} />
  } else {
    return <Dialog fullScreen {...props as DialogProps} />
  }
}

export default FiltersPopover
