import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Text,
} from 'components'

export type ConfirmDialogProps = {
  open: boolean
  onClose: () => void
  title?: React.ReactElement
  content?: React.ReactElement
  action?: React.ReactElement
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ title, content, action, open, onClose }) => {
  return <Dialog open={open} onClose={onClose}>
    <DialogTitle component={Box}>
      { title || <Text variant="h6">Are you sure?</Text> }
    </DialogTitle>

    <DialogContent>
      { content }
    </DialogContent>

    <DialogActions>
      { action }
      <Button onClick={onClose}>Cancel</Button>
    </DialogActions>
  </Dialog>
}

export default ConfirmDialog