import React from 'react'
import { Box, BoxProps } from '../Box'
import styled from '@mui/material/styles/styled'

export type PageHeaderProps = BoxProps & { noBorder?: boolean }

export const PageHeaderBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'noBorder' })<PageHeaderProps>(({ theme, noBorder }) => ({
  width: '100%',
  ...(!noBorder && {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.divider,
  }),
  paddingBottom: theme.spacing(1),
  paddingTop: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}))

export const PageHeader: React.FC<React.PropsWithChildren<PageHeaderProps>> = (props) => {
    return <PageHeaderBox {...props} />
}

export default PageHeader
