import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Link, Text } from 'components'
import { TaskWithComponent } from './Todo'
import { TodoCard } from './TodoCard'
import { useTodosWizard, RefetchFn } from 'hooks/useTodosWizard'

export interface TodosProps {
  todos: TaskWithComponent[]
  refetch: RefetchFn
}

export const Todos: React.FC<TodosProps> = ({ todos, refetch }) => {
  const wizard = useTodosWizard({ todos, refetch })

return <Box>
    <Text variant="h4">Todo Tasks</Text>
    <Text mt={2}>Here you will find tasks to help guide you through setting up and maintaining your Ordo meal program.</Text>

    { wizard.todos.length > 0 && wizard.incomplete().length === 0 && <Box mt={4} pb={4} display="flex" flexDirection="column" maxWidth={800} borderBottom="1px solid #ccc">
      <Text variant="h6" mb={2}>Congratulations! You&apos;ve completed all your assigned tasks.</Text>
      <Text>You can review your completed and ignored tasks below if you would like to make any changes. <strong>Completed tasks will disappear after a few hours</strong>, but you can always <Link to="/settings" component={RouterLink}>visit your settings</Link> to make additional changes.</Text>
    </Box> }

    { wizard.todos.length === 0 && <Box mt={4} pb={4} display="flex" flexDirection="column" maxWidth={800}>
      <Text variant="h6" mb={2}>Nothing to do! You&apos;ve cleared all your tasks.</Text>
      <Text><strong>Completed tasks will disappear after a few hours</strong>, but you can always <Link to="/settings" component={RouterLink}>visit your settings</Link> to make additional changes.</Text>
    </Box> }

    <Box mt={4} display="flex" flexDirection="column" maxWidth={800}>
      { wizard.incomplete((todo) => <TodoCard key={todo.id} todo={todo} wizard={wizard} />) }
      { wizard.ignored((todo) => <TodoCard key={todo.id} todo={todo} wizard={wizard} />) }
      { wizard.completed((todo) => <TodoCard key={todo.id} todo={todo} wizard={wizard} />) }
    </Box>
  </Box>
}

export default Todos
