import React from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import { Box } from 'components'
import OrdoLogo from 'theme/ordo-logo.svg'

export const Loader: React.FC = () => {
  return <Box sx={{
    textAlign: 'center',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center'
  }}>
    <Box width="200px" height="auto">
      <OrdoLogo width="200px" />
      <LinearProgress color="primary" />
    </Box>
  </Box>
}

export default Loader
