import MuiAlert from '@mui/material/Alert'

declare module '@mui/material/Alert' {
  interface AlertPropsColorOverrides {
    plain: true
    branding: true
    campus: true
    kitchen: true
    kiosk: true
    luxo: true
    market: true
    tools: true
  }

  // EXAMPLE: Defining a variant="whatever" prop for alerts - see also /src/theme/index.ts for theme definition example
  // interface AlertPropsVariantOverrides {
  //   whatever: true
  // }
}

export const Alert = MuiAlert

export default Alert
