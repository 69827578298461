import React, { useState, useMemo } from 'react'
import PlainTextField from './PlainTextField'
import IconButton from '../IconButton'
import { InputAdornment } from '../Input'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

const PasswordField = (props) => {
  const [type, setType] = useState(props.type)

  const handleToggleType = () => {
    setType((t) => (t === 'password') ? 'text' : 'password')
  }

  const handleChange = (e) => {
    if (props.onChange) props.onChange(e)
  }

  const overrides = useMemo(() => (
    {
      type,
      InputProps: {
        endAdornment: (
          <InputAdornment position={props.position}>
            <IconButton onClick={handleToggleType} tabIndex={-1}>
              {
                (type === 'password')
                  ? <VisibilityOffIcon />
                  : <VisibilityIcon />
              }
            </IconButton>
          </InputAdornment>
        )
      }
    }
  ), [type, props.position])

  return <PlainTextField {...props} {...overrides} onChange={handleChange} />
}

PasswordField.defaultProps = {
  variant: 'outlined',
  position: 'end',
  icon: null,
}

export default PasswordField
