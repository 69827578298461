import { gql } from 'graphql/schema/gql'

export const updateMeMutation = gql(`
  mutation updateMeMutation($input: UpdateMeInput!) {
    updateMe(input: $input) {
      me {
        ...meFragment
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default updateMeMutation
