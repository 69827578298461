import { useCallback, useMemo, useState } from 'react'

export const usePopoverState = () => {
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorElement(event.currentTarget)
  const handleClose = useCallback(() => setAnchorElement(null), [setAnchorElement])
  const open = useMemo(() => !!anchorElement, [anchorElement])

  return {
    open,
    anchorElement,
    setAnchorElement,
    handleClick,
    handleClose,
  }
}

export default usePopoverState