import { ignoreTodoMutation } from 'graphql/mutations'
import { MutationTuple, useMutation } from './useMutation'
import type { IgnoreTodoInput, IgnoreTodoPayload } from 'graphql/schema/graphql'
export type { IgnoreTodoInput }

export type IgnoreTodoMutationData = { ignoreTodo: IgnoreTodoPayload }

export const useIgnoreTodo: (variables?: IgnoreTodoInput) => MutationTuple<IgnoreTodoMutationData, IgnoreTodoInput> = (variables)  => {
  const [ ignoreTodoFn, ignoreTodoResult ] = useMutation<IgnoreTodoMutationData, IgnoreTodoInput>(
    ignoreTodoMutation,
    {
      variables,
      // notifyOnNetworkStatusChange: true
    }
  )

  return [ ignoreTodoFn, ignoreTodoResult ]
}

export default useIgnoreTodo
