import React from 'react'
import { TextField, TextFieldProps } from 'components/TextField'
import { getIn, useFormikContext } from 'formik'

export const FormFileInput: React.FC<TextFieldProps & { multiple?: boolean, pl?: number }> = ({ name, multiple=false, pl=4, ...props }) => {
  const { touched, errors, values, setFieldValue } = useFormikContext()

  return (
    <TextField
      {...props}
      error={Boolean(getIn(touched, name) && getIn(errors, name))}
      helperText={getIn(touched, name) && getIn(errors, name)}
      type="file"
      inputProps={{
        multiple: multiple,
        sx: {
          pl: getIn(values, name) ? 4 : pl,
          '&:focus': { pl: 4 }
        }
      }}
      onChange={event => {
        if (multiple) {
          setFieldValue(name, (event.currentTarget as HTMLInputElement).files)
        } else {
          setFieldValue(name, (event.currentTarget as HTMLInputElement).files[0])
        }
      }}
    />
  )
}

export default FormFileInput
