import React from 'react'
import MuiDialog, { DialogProps } from '@mui/material/Dialog'
import MuiDialogActions, { DialogActionsProps } from '@mui/material/DialogActions'
import MuiDialogContent, { DialogContentProps } from '@mui/material/DialogContent'
import MuiDialogContentText, { DialogContentTextProps } from '@mui/material/DialogContentText'
import MuiDialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle'

export type {
  DialogProps,
  DialogActionsProps,
  DialogContentProps,
  DialogContentTextProps,
  DialogTitleProps,
}

export const Dialog = (props: DialogProps) => <MuiDialog {...props} />
export const DialogActions = (props: DialogActionsProps) => <MuiDialogActions {...props} />
export const DialogContent = (props: DialogContentProps) => <MuiDialogContent {...props} />
export const DialogContentText = (props: DialogContentTextProps) => <MuiDialogContentText {...props} />
export const DialogTitle = <C extends React.ElementType='span'>(props: DialogTitleProps<C, { component?: C }>) => <MuiDialogTitle {...props} />

export default Dialog