import React from 'react'
import { DatePicker, DatePickerProps } from 'components/DatePicker'
import { getIn, useFormikContext } from 'formik'
import { DateTime } from 'luxon'

type FormDatePickerProps = DatePickerProps<DateTime> & { id?: string, name?: string, helperText?: string } // TODO: shouldn't these be on the input already? maybe we need to pull in input props?

export const FormDatePicker: React.FC<FormDatePickerProps> = ({ name, helperText, ...props }) => {
  const { touched, errors, values, setFieldValue} = useFormikContext()

  return (
    <DatePicker
      {...props}
      slotProps={{
        textField: {
          error: Boolean(getIn(touched, name) && getIn(errors, name)),
          helperText: ((getIn(touched, name) && getIn(errors, name)) || helperText),
          value: getIn(values, name) && DateTime.fromISO(getIn(values, name).toISOString())
        }
      }}
      onChange={date => {
        if (date && date.isValid) setFieldValue(name, date.toJSDate())
      }}
      onAccept={date => {
        setFieldValue(name, date.toJSDate())
      }}
    />
  )
}

export default FormDatePicker
