import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Alert, Button, Form, FormTextField, FormSubmitButton, Box, Text } from 'components'
import { useForgotPasswordForm } from 'hooks'

export const ForgotPasswordForm = () => {
  const [form, { loading, submitted, errors }] = useForgotPasswordForm()

  if (submitted) {
    return <Box>
      <Box pt={2}>
        <Text>Please check your email for a link and instructions to reset your password.</Text>
      </Box>

      <Box py={2}>
        <Button component={RouterLink} to="/login">Back to login</Button>
      </Box>
    </Box>
  } else {
    return <Form form={form}>
      <Text textAlign="center" mb={2}>Enter your email address and we&apos;ll send you password reset instructions.</Text>

      <FormTextField
        id="email"
        name="email"
        label="Email address"
        autoFocus={true}
        autoComplete="none"
      />

      { !loading && <>{ errors.map((error) => <Alert key={`${error.code}:${error.message}`} severity="error" sx={{ width: '100%', mb: 2 }}>
        {error.message}
      </Alert>)}</> }

      <FormSubmitButton
        loading={loading}
      >
        Send me reset password instructions
      </FormSubmitButton>
    </Form>
  }
}

export default ForgotPasswordForm
