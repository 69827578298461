import { gql } from 'graphql/schema/gql'

export const resolveTodoMutation = gql(`
  mutation resolveTodoMutation($input: ResolveTodoInput!) {
    resolveTodo(input: $input) {
      todo {
        ...todoFragment
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default resolveTodoMutation
