import { MouseEvent, TouchEvent } from 'react'
import { useNavigate } from 'react-router'
import store from 'store2'
import apolloClient from 'graphql/client'
import { useToken, useDrawerSettings } from 'hooks'

export const useLogout: (navigateTo?: string | false) => (e: MouseEvent | TouchEvent) => void = (navigateTo='/login') => {
  const { token, setToken } = useToken()
  const apollo = apolloClient(token)
  const navigate = useNavigate()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ _drawerState, { setNavDrawer } ] = useDrawerSettings()

  const logout = (e: MouseEvent): void => {
    e.preventDefault()
    setToken(undefined, undefined)
    apollo.resetStore()
    setNavDrawer(false)
    store(false)
    if (navigateTo)
      navigate(navigateTo, { replace: true })
  }

  return logout
}

export default useLogout
