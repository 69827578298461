import React, { PropsWithChildren } from 'react'
import Drawer, { DrawerProps } from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import { useViewport } from 'hooks'

export type ContentDrawerProps = {
  setDrawer: (open: boolean) => void
  anchor?: "bottom" | "left" | "right" | "top"
} & DrawerProps

type DrawerContainerProps = Pick<ContentDrawerProps, "sx" | "setDrawer" | "anchor" | "variant" | "open">

const DrawerContainer: React.FC<PropsWithChildren<DrawerContainerProps>> = ({ children, open, variant, setDrawer, anchor="right", ...props }) => {
  if (variant === "persistent" && !open) return null

  return <Drawer {...props} anchor={anchor} open={open} variant={variant} onClose={() => setDrawer(false)}>
    <Toolbar />

    { children }
  </Drawer>
}

export const ContentDrawer: React.FC<PropsWithChildren<ContentDrawerProps>> = (props) => {
  const { isLaptop } = useViewport()

  return <>
    <DrawerContainer {...props} variant={isLaptop ? "persistent" : "temporary"} />
  </>
}

export default ContentDrawer