import { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router'
import { useToken } from './useToken'
import type { LocationWithFromPath } from 'types/routes'

export const useTokenLogin = () => {
  const { setToken } = useToken()
  const navigate = useNavigate()
  const location: LocationWithFromPath = useLocation()
  const params = new URLSearchParams(location.search)
  const urlToken: string | undefined = params.get('token')
  const urlRefreshToken: string | undefined = params.get('refresh')

  useEffect(() => {
    if (urlToken && urlRefreshToken){
      setToken(urlToken, urlRefreshToken)
      navigate(location.pathname, { replace: true })
    }
  }, [urlToken, urlRefreshToken, location, setToken, navigate])

  return {}
}

export default useTokenLogin