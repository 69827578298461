import React, { useEffect } from 'react'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { withSentryReactRouterV6Routing } from '@sentry/react'
import { Todos, TodosProps } from './Todos'
import { Todo } from './Todo'

const SentryRoutes = withSentryReactRouterV6Routing(Routes)

export const TodoRoute: React.FC<TodosProps> = ({ todos, refetch }) => {
  const navigateTo = useNavigate()
  const { id } = useParams()
  const todo = todos?.find((task) => task.id === id)

  useEffect(() =>{
    if (!!todos && !todo) navigateTo('/todos', { replace: true })
  }, [ todos, todo, navigateTo ])

  return <Todo key={id} todos={todos} todo={todo} refetch={refetch} />
}

export const Router: React.FC<TodosProps> = ({ todos, refetch }) => {
  return <SentryRoutes>
    <Route path="/" element={<Todos todos={todos} refetch={refetch} />} />
    <Route path="/:id" element={<TodoRoute todos={todos} refetch={refetch} />} />
  </SentryRoutes>
}

export default Router
