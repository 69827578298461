import React from 'react'
import { Box, BoxProps } from '../Box'
import { IconButton } from '../IconButton'
import { Close as CloseIcon } from 'icons'
import styled from '@mui/material/styles/styled'

export type PopoverHeaderProps = BoxProps & { noBorder?: boolean, onClose?: () => void }

export const PopoverHeaderBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'noBorder' })<PopoverHeaderProps>(({ theme, noBorder }) => ({
  width: '100%',
  ...(!noBorder && {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.divider,
  }),
  paddingBottom: theme.spacing(1),
  paddingTop: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
  },
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
  },
}))

export const PopoverHeader: React.FC<React.PropsWithChildren<PopoverHeaderProps>> = ({ children, onClose, ...props }) => {
    return <PopoverHeaderBox {...props}>
      <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
        <Box flexGrow={1}>
          {children}
        </Box>

        <Box>
          <IconButton
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </PopoverHeaderBox>
}

export default PopoverHeader
