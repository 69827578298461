import { useLazyQuery as useLazyApolloQuery, OperationVariables, QueryHookOptions, DocumentNode, TypedDocumentNode, LazyQueryExecFunction, QueryResult } from '@apollo/client'
import { DateTime } from 'luxon'

export type LazyQueryResultTuple<TData, TVariables> = [ LazyQueryExecFunction<TData, TVariables>, QueryResult<TData, TVariables> & { loadedAt?: DateTime } ]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useLazyQuery = <TData = any, TVariables = OperationVariables>(query: DocumentNode | TypedDocumentNode<TData, TVariables>, options?: QueryHookOptions<TData, TVariables>): LazyQueryResultTuple<TData, TVariables> => {
  return useLazyApolloQuery<TData, TVariables>(query, { fetchPolicy: 'cache-and-network', ...options })
}

export default useLazyQuery