import React, { useCallback } from 'react'
import { Button } from 'components'
import { ButtonProps } from '@mui/material'

export type onSelectFn = (type: string) => void

export type HelpOption = {
  type: string
  name: string
  notesRequired?: boolean
}

export type HelpDialogOptionProps = Omit<HelpOption, 'notesRequired'> & Omit<ButtonProps, 'onSelect' | 'type'> & {
  active?: boolean
  onSelect?: onSelectFn
}

export const HelpDialogOption: React.FC<HelpDialogOptionProps> = ({ type, name, active=false, onSelect, ...props }) => {
  const onClick: React.MouseEventHandler = useCallback(() => {
    if (onSelect) onSelect(type)
  }, [ onSelect, type ])

  return <Button {...props} onClick={onClick} variant={active ? 'contained' : 'outlined'} sx={{ borderRadius: 0 }}>
    { name }
  </Button>
}

export default HelpDialogOption
