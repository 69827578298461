import { useMediaQuery, useTheme } from '@mui/material'
import { useDrawerSettings } from 'hooks/useDrawerSettings'
import { useMemo } from 'react'

export const useViewport = () => {
  const theme = useTheme()
  const [{ navDrawerOpen, contentDrawerOpen }] = useDrawerSettings()

  const isExtraSmall = useMediaQuery(theme.breakpoints.up('xs'))
  const isSmall = useMediaQuery(theme.breakpoints.up('sm'))
  const isMedium = useMediaQuery(theme.breakpoints.up('md'))
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))
  const isExtraLarge = useMediaQuery(theme.breakpoints.up('xl'))
  const isXXLarge = useMediaQuery(theme.breakpoints.up('xxl'))

  const drawerOpen = useMemo(() => (navDrawerOpen || contentDrawerOpen), [navDrawerOpen, contentDrawerOpen])
  const drawersOpen = useMemo(() => (navDrawerOpen && contentDrawerOpen), [navDrawerOpen, contentDrawerOpen])

  // TODO: We could also codify some of these as actual breakpoints in our theme, but this helps us factor in whether drawers are open
  const isMobile = useMemo(() => isExtraSmall, [isExtraSmall])
  const isTablet = useMemo(() => isMedium, [isMedium])
  const isLaptop = useMemo(() => isExtraLarge || (isLarge && !drawerOpen), [isLarge, isExtraLarge, drawerOpen])
  const isDesktop = useMemo(() => isXXLarge || (isExtraLarge && !drawerOpen), [isExtraLarge, isXXLarge, drawerOpen])

  return {
    useMediaQuery,
    breakpoints: theme.breakpoints,
    isExtraSmall,
    isSmall,
    isMedium,
    isLarge,
    isExtraLarge,
    isXXLarge,
    isMobile,
    isTablet,
    isLaptop,
    isDesktop,
    drawerOpen,
    drawersOpen,
  }
}

export default useViewport
