import React, { useMemo } from 'react'
import { Badge, BadgeProps } from 'components/Badge'
import { TodoTask } from 'graphql/schema/graphql'

export type TodosBadgeProps = BadgeProps & {
  todos: TodoTask[]
  type?: string | string[]
}

export const TodosBadge: React.FC<React.PropsWithChildren<TodosBadgeProps>> = ({ todos, children, type, color="error", ...props }) => {
  const filteredTodos = useMemo(() => {
    return todos?.reduce((previous, current) => {
      if (current.status !== "incomplete") return previous

      if (type) {
        if (Array.isArray(type)) {
          if (type.includes(current.definition.type)) return previous + 1
        } else {
          if (current.definition.type === type) return previous + 1
        }
      } else {
        return previous + 1
      }

      return previous
    }, 0) || 0
  }, [ todos, type ])

  return <Badge {...props} color={color} badgeContent={filteredTodos}>
    {children}
  </Badge>
}

export default TodosBadge
