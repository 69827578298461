import type { StatesProvincesQueryQuery, StatesProvincesQueryQueryVariables } from 'graphql/schema/graphql'
import { statesProvincesQuery } from 'graphql/queries'
import { useQuery } from 'hooks/useQuery'
import { publicClient } from 'graphql/client'

type StatesProvincesArgs = {
  pollInterval?: number
}

export const useStatesProvinces = (args?: StatesProvincesArgs) => {
  const { data, ...rest } = useQuery<StatesProvincesQueryQuery, StatesProvincesQueryQueryVariables>(
    statesProvincesQuery,
    {
      pollInterval: args?.pollInterval,
      // notifyOnNetworkStatusChange: true,
      client: publicClient,
    }
  )

  return {
    ...rest,
    data: {
      statesProvinces: [],
      ...data,
    }
  }
}

export default useStatesProvinces
