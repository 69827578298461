import React, { useCallback, useMemo, useState } from 'react'
import { AsYouType } from 'libphonenumber-js'
import PlainTextField from './PlainTextField'
import { TextFieldProps } from '@mui/material/TextField'

const TelephoneField: React.FC<TextFieldProps> = (props) => {
  const [ value, setValue ] = useState(props.value)

  const handleChange = useCallback((e) => {
    e.target.value = e.target.value.replace(/\D/g, '')
    setValue(e.target.value)
    if (props.onChange) props.onChange(e)
  }, [props, setValue])

  const formatted = useMemo(() => {
    if (value && typeof value === 'string' && (value?.length === 0 || value?.length > 3)) {
      return new AsYouType("US").input(value)
    } else {
      return value || ''
    }
  }, [ value ])

  return <PlainTextField {...props} value={formatted} type="tel" onChange={handleChange} />
}

TelephoneField.defaultProps = {
  variant: 'outlined',
}

export default TelephoneField
