import React from 'react'
import { Box, BoxProps } from '../Box'
import styled from '@mui/material/styles/styled'

export type PopoverFooterProps = BoxProps & { noBorder?: boolean }

export const PopoverFooterBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'noBorder' })<PopoverFooterProps>(({ theme, noBorder }) => ({
  width: '100%',
  ...(!noBorder && {
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.divider,
  }),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}))

export const PopoverFooter: React.FC<React.PropsWithChildren<PopoverFooterProps>> = (props) => {
    return <PopoverFooterBox {...props} />
}

export default PopoverFooter
