import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { styled, StepIconProps } from '@mui/material'
import { Check as CheckIcon, Ignore as IgnoreIcon } from 'icons'
import { TaskWithComponent } from '../Todo'
import { Link } from 'components'

const StepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean, ignored?: boolean, resolved?: boolean };
}>(({ theme, ownerState }) => ({
  borderStyle: 'solid',
  borderWidth: [1],
  borderColor: theme.palette.primary.main,
  color: theme.palette.primary.main,
  zIndex: 1,
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.ignored && {
    borderColor: '#ccc',
    color: '#ccc',
  }),
  ...(ownerState.active && {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: '#fff',
    fontWeight: 'bold',
  }),
  ...(!ownerState.active && ownerState.resolved && {
    borderColor: theme.palette.success.light,
    color: theme.palette.success.light,
  }),
  ...(ownerState.active && ownerState.resolved && {
    backgroundColor: theme.palette.success.light,
    borderColor: theme.palette.success.light,
    color: '#fff',
  }),
  ...(!ownerState.active && ownerState.completed && {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
  }),
  ...(ownerState.active && ownerState.completed && {
    backgroundColor: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    color: '#fff',
  }),
}))

export type TodoStepIconProps = StepIconProps & { todo: TaskWithComponent }

export const StepIcon: React.FC<TodoStepIconProps> = (props) => {
  const { active, completed, className, todo } = props
  const ignored: boolean = todo.status === "ignored"
  const resolved: boolean = todo.resolved
  const TodoIcon = todo.icon

  return (
    <Link to={`/todos/${todo.id}`} component={RouterLink}>
      <StepIconRoot ownerState={{ completed, active, ignored, resolved }} className={className}>
        {active ? (
          <TodoIcon className="StepIcon-circle" />
        ) : (
          completed ? (
            <CheckIcon className="StepIcon-completedIcon" />
          ) : (
            ignored ? (
              <IgnoreIcon className="StepIcon-circle" />
            ) : (
              <TodoIcon className="StepIcon-circle" />
            )
          )
        )}
      </StepIconRoot>
    </Link>
  )
}

export default StepIcon