import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'compositions/Application/store'
import { ClientError } from 'graphql/schema/graphql'

// export type HelpOption = {
//   type: string
//   name: string
//   notesRequired?: boolean
// }

// export type HelpRequestState = {
//   requestId?: string
//   selected?: string
//   notes?: string
//   errors?: ClientError[]
// }

export interface HelpDialogState {
  open: boolean
  // helpRequest?: HelpRequestState
  notes: string
  errors: ClientError[]
  selected?: string
  requestId?: string
}

const initialState: HelpDialogState = {
  open: false,
  notes: '',
  errors: [],
  selected: undefined,
  requestId: undefined,
}

export const helpDialogSlice = createSlice({
  name: 'helpDialog',
  initialState,
  reducers: {
    setHelpDialog: (state, action: PayloadAction<Partial<HelpDialogState>>) => {
      Object.assign(state, action.payload)
    },

    toggleHelpDialog: (state) => {
      state.open = !state.open
    },

    openHelpDialog: (state) => {
      state.open = true
    },

    closeHelpDialog: (state) => {
      state.open = false
    },

    clearHelpDialog: (state) => {
      Object.assign(state, initialState)
    },
  },
})

export const {
  setHelpDialog,
  toggleHelpDialog,
  openHelpDialog,
  closeHelpDialog,
  clearHelpDialog,
} = helpDialogSlice.actions

export const helpDialogSelector = (state: RootState) => state.helpDialog

export default helpDialogSlice.reducer