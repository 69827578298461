import React, { useEffect } from 'react'
import { NavigateOptions, To, useNavigate } from 'react-router-dom'

export const Redirect: React.FC<{ to: To, options?: NavigateOptions }> = ({ to, options }) => {
  const navigateTo = useNavigate()
  useEffect(() => navigateTo(to, options), [ navigateTo, to, options ])
  return null
}

export default Redirect
