import { gql } from 'graphql/schema/gql'

export const ignoreTodoMutation = gql(`
  mutation ignoreTodoMutation($input: IgnoreTodoInput!) {
    ignoreTodo(input: $input) {
      todo {
        ...todoFragment
      }
      errors {
        code
        message
        path
      }
    }
  }
`)

export default ignoreTodoMutation
