import React, { PropsWithChildren } from 'react'
import { useMe } from 'hooks'
import { Box, Text } from 'components'
import { Location, Organization } from 'graphql/schema/graphql'

export const Unauthorized: React.FC = () =>
  <Box maxWidth={[1]} mx="auto">
    <Text variant="h2">Not Authorized</Text>
    <Text component="p">You do not have permission to access this resource</Text>
  </Box>

export const AuthorizedRole: React.FC<PropsWithChildren<{ role: string, context: Organization | Location }>> = ({ role, context, children }) => {
  const { hasRole } = useMe()
  const auth = hasRole(role, context)

  if (auth) {
    return <>{children}</>
  } else {
    return <Unauthorized />
  }
}

export const AuthorizedLevel: React.FC<PropsWithChildren<{ level: number, context: Organization | Location }>> = ({ level, context, children }) => {
  const { hasLevel } = useMe()
  const auth = hasLevel(level, context)

  if (auth) {
    return <>{children}</>
  } else {
    return <Unauthorized />
  }
}

export const Authorized: React.FC<PropsWithChildren<{ role?: string, level?: number, context: Organization | Location }>> = ({ role, level, context, children }) => {
  if (role !== undefined) {
    return <AuthorizedRole role={role} context={context}>{children}</AuthorizedRole>
  } else if (level !== undefined) {
    return <AuthorizedLevel level={level} context={context}>{children}</AuthorizedLevel>
  } else {
    return <Unauthorized />
  }
}

export default Authorized
