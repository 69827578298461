import { useAppDispatch, useAppSelector } from 'compositions/Application/store'
import {
  helpDialogSelector,
  setHelpDialog,
  toggleHelpDialog,
  openHelpDialog,
  closeHelpDialog,
  clearHelpDialog,
  HelpDialogState,
} from 'reducers/helpDialog'

export type { HelpDialogState }

export interface HelpDialogActions {
  setHelpDialog: (state: Partial<HelpDialogState>) => ReturnType<typeof setHelpDialog>
  toggleHelpDialog: () => ReturnType<typeof toggleHelpDialog>
  openHelpDialog: () => ReturnType<typeof openHelpDialog>
  closeHelpDialog: () => ReturnType<typeof closeHelpDialog>
  clearHelpDialog: () => ReturnType<typeof clearHelpDialog>
}

export const useHelpDialog = (): HelpDialogState & HelpDialogActions => {
  const helpDialog = useAppSelector(helpDialogSelector)
  const dispatch = useAppDispatch()

  const setHelpDialogCallback = (state: Partial<HelpDialogState>) => dispatch(setHelpDialog(state))
  const toggleHelpDialogCallback = () => dispatch(toggleHelpDialog())
  const openHelpDialogCallback = () => dispatch(openHelpDialog())
  const closeHelpDialogCallback = () => dispatch(closeHelpDialog())
  const clearHelpDialogCallback = () => dispatch(clearHelpDialog())

  return {
    ...helpDialog,
    setHelpDialog: setHelpDialogCallback,
    toggleHelpDialog: toggleHelpDialogCallback,
    openHelpDialog: openHelpDialogCallback,
    closeHelpDialog: closeHelpDialogCallback,
    clearHelpDialog: clearHelpDialogCallback,
  }
}

export default useHelpDialog
