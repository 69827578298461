import React from 'react'
import FormField, { FieldProps } from '../FormField'
import { getIn } from 'formik'
import Text from 'components/Text'

type FormFieldErrorProps = Partial<FieldProps> & { id: string, name: string }

export const FormFieldError: React.FC<FormFieldErrorProps> = ({ name }) => {
  return (
    <FormField
      name={name}
      component={({ form }) => {
        const { touched, errors } = form
        return <Text color="error">{ getIn(touched, name) && getIn(errors, name) }</Text>
      }}
    />
  )
}

export default FormFieldError
