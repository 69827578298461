import React from 'react'
import styled from '@mui/material/styles/styled'
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid'
import { Text, Link } from 'components'
import { DateTime } from 'luxon'
import { Document, SelectOption } from 'graphql/schema/graphql'

export type DocumentsListProps = { documents: Document[], documentTypeOptions: SelectOption[] }

const StyledDataGrid = styled(DataGrid)({
  borderTopColor: 'transparent',
  borderLeftColor: 'transparent',
  borderRightColor: 'transparent',

  // '.MuiDataGrid-columnHeaders': {
  //   // borderColor: 'transparent',
  // },

  '.MuiDataGrid-footerContainer': {
    borderColor: 'transparent',
  },

  // '.MuiDataGrid-cell': {
  //   // borderColor: 'transparent',
  // },

  '.MuiDataGrid-columnHeader--moving': {
    backgroundColor: 'inherit',
  },
})

export const DocumentsList: React.FC<DocumentsListProps> = ({ documents, documentTypeOptions }) => {
  const columns: GridColDef[] = [
    {
      field: 'documentType',
      headerName: 'Document',
      flex: 2,
      valueGetter: (params: GridValueGetterParams<Document, Document>) => documentTypeOptions?.find((option) => option.value == params.row.documentType)?.label,
    },
    {
      field: 'createdAt',
      headerName: 'Uploaded',
      flex: 1,
      // valueGetter: (params: GridValueGetterParams<Document, Document>) => DateTime.fromISO(params.row.createdAt).toLocaleString(DateTime.DATE_SHORT),
      renderCell: (params: GridRenderCellParams<Document>) => {
        return <Text>{DateTime.fromISO(params.row.createdAt).toLocaleString(DateTime.DATE_SHORT)}</Text>
      },
    },
    {
      field: 'expiresAt',
      headerName: 'Expires at',
      flex: 1,
      // valueGetter: (params: GridValueGetterParams<Document, Document>) => DateTime.fromISO(params.row.expiresAt).toLocaleString(DateTime.DATE_SHORT),
      renderCell: (params: GridRenderCellParams<Document>) => {
        if (params.row.expiresAt) {
          return <Text>{DateTime.fromISO(params.row.expiresAt).toLocaleString(DateTime.DATE_SHORT)}</Text>
        }
      },
    },
    {
      field: '',
      headerName: '',
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<Document>) => {
        return <Link href={params.row.file} target="_blank">Download</Link>
      },
    },
  ]

  return <StyledDataGrid
    columns={columns}
    rows={[...documents].sort((a,b) => a.documentType.localeCompare(b.documentType))}
    getRowHeight={() => 80}
    rowSelection={false}
    hideFooter
    autoHeight
  />
}

export default DocumentsList
