import React from "react"
import { Autocomplete, Badge, Text, TextField } from 'components'

export interface Option { label: string, id: string, todoCount?: number }

export const LocationSelector: React.FC<{ label: string, options: Option[], selected: Option, onChange: (value: Option) => void }> = ({ label, options, selected, onChange }) => {

  return <Autocomplete
    disablePortal
    disableClearable
    options={options || null}
    value={selected}
    sx={{ width: '100%' }}
    renderInput={(params) => <TextField {...params} label={label} />}
    renderOption={(props, option) => {
      return <li {...props} key={option.id}>
        { !!(option.todoCount && option.todoCount > 0) && <Badge badgeContent={option.todoCount} color="error" variant="dot" anchorOrigin={{ vertical: 'top', horizontal: 'left'}} sx={{ width: '100%' }}><Text whiteSpace="break-spaces">{ option.label }</Text></Badge> }
        { !(option.todoCount && option.todoCount > 0) && <Text whiteSpace="break-spaces">{ option.label }</Text> }
      </li>
    }}
    isOptionEqualToValue={(option, value) => option.id === value?.id}
    onChange={(_e, value: Option) => onChange(value)}
  />
}

export default LocationSelector
