import React from 'react'
import MuiCard, { CardProps } from '@mui/material/Card'
import MuiCardActionArea, { CardActionAreaProps } from '@mui/material/CardActionArea'
import MuiCardActions, { CardActionsProps } from '@mui/material/CardActions'
import MuiCardContent, { CardContentProps } from '@mui/material/CardContent'
import MuiCardHeader, { CardHeaderProps } from '@mui/material/CardHeader'
import MuiCardMedia, { CardMediaProps } from '@mui/material/CardMedia'

export const Card = <C extends React.ElementType='div'>(props: CardProps<C, { component?: C }>) => <MuiCard {...props} />
export const CardActionArea = (props: CardActionAreaProps) => <MuiCardActionArea {...props} />
export const CardActions = (props: CardActionsProps) => <MuiCardActions {...props} />
export const CardContent = (props: CardContentProps) => <MuiCardContent {...props} />
export const CardHeader = (props: CardHeaderProps) => <MuiCardHeader {...props} />
export const CardMedia = <C extends React.ElementType='img'>(props: CardMediaProps<C, { component?: C }>) => <MuiCardMedia {...props} />

export default Card
